import type { JsonSchema, JsonUISchema } from "@shared/form-builder";

/*
{ 
  "en": {
    "key": "value"
  },
  "es": {
    "key": "value"
  }
}
*/

export function getTranslationsSchema(fields: string[], languages: string[]): JsonSchema {
  const schema: JsonSchema = {
    type: "object",
    title: "Traducciones",
    properties: {}
  };

  schema.properties = languages.reduce(
    (acc, lang: string) => {
      acc[lang] = {
        type: "object",
        title: lang,
        properties: fields.reduce(
          (acc, field: string) => {
            acc[field] = {
              type: "string",
              title: field
            };
            return acc;
          },
          {} as Record<string, JsonSchema>
        )
      };

      return acc;
    },
    {} as Record<string, JsonSchema>
  );

  return schema;
}

export function getTranslationsUISchema(fields: string[], languages: string[]): JsonUISchema {
  const schema: JsonUISchema = {
    type: "ModalTabsLayout",
    tabs: languages.map((lang: string) => ({
      title: lang,
      icon: "language",
      elements: [
        {
          type: "Label",
          text: "Modifica los textos del componente para cada idioma disponible",
          options: {
            icon: "info"
          }
        },
        ...fields.map((field: string) => ({
          type: "Control",
          scope: `#/properties/${lang}/properties/${field}`
        }))
      ] as JsonUISchema[] // Why???
    }))
  };

  return schema;
}
