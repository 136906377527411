import { useCallback, useEffect, useState } from "react";
import { DEFAULT_LANGUAGE, getDefaultElement, type Asset, type GameElement } from "@shared/game-engine";

const DATA_CLIPBOARD_MIME = "application/x-ufolab-clipboard-data";

interface Meta extends Record<string, unknown> {
  ts: number;
}

interface Options {
  pasteOnce?: boolean;
}

interface ClipboardData<T = GameElement[]> {
  meta: Meta;
  content: T;
  pasteOnce?: boolean;
  pasteCount?: number;
}

type CopiedValueFallback = string | null;

type CopyFn = (input: GameElement[], meta?: Partial<Meta>, options?: Options) => Promise<boolean>;
type getDataFn = () => Promise<ClipboardData | null>;
type hasDataFn = () => boolean;

interface TransformerHooks {
  onImage?: (data: Blob, mimetype: string, name?: string) => Promise<Asset | null>;
}

const transformers: Record<string, (data: Blob, hooks: TransformerHooks) => Promise<GameElement[] | null>> = {
  "image/png": async (data, { onImage }) => {
    if (!onImage) {
      return null;
    }

    const asset = await onImage(data, "image/png");

    if (!asset) {
      return null;
    }

    return [
      {
        ...getDefaultElement(),
        name: "image",
        component: "<image>",
        stage: {
          ...getDefaultElement().stage,
          // TODO: Apply some offset, but we should calculate the center of the viewport
          x: 100,
          y: 100,
          // let the editor decide position and size
          width: null,
          height: null
        },
        properties: {
          src: asset.path // This already includes the ASSET_PROTOCOL
        }
      }
    ];
  },

  "text/plain": async (data) => {
    return [
      {
        ...getDefaultElement(),
        name: "text",
        component: "<text>",
        stage: {
          ...getDefaultElement().stage,
          x: 100,
          y: 100,
          width: 400,
          height: null
        },
        properties: {
          // From "parrafo" example
          fontFamily: "Inter",
          fontSize: "16pt",
          bold: false,
          italic: false,
          color: "#000000",
          textAlign: "left",
          verticalAlign: "top"
        },
        translations: {
          [DEFAULT_LANGUAGE]: {
            text: await data.text()
          }
        }
      }
    ];
  },

  "text/html": async (data) => {
    const text = await data.text();
    const parser = new DOMParser();
    const doc = parser.parseFromString(text, "text/html");
    const element = doc.body.querySelector<HTMLDivElement>(`div[data-mimetype="${DATA_CLIPBOARD_MIME}"]`);

    if (!element) {
      return null;
    }

    try {
      const dataContent = element.getAttribute("data-content");
      const decodedDataContent = dataContent ? atob(dataContent) : null;
      const elementData = decodedDataContent ? (JSON.parse(decodedDataContent) as ClipboardData) : null;

      if (!elementData) {
        return null;
      }

      if (!elementData.content) {
        console.warn("Invalid clipboard data");
        console.debug(elementData);
        return null;
      }

      return elementData.content;
    } catch (error) {
      console.warn("Invalid clipboard data", error);
      return null;
    }
  }
};

function createClipboardItem(data: ClipboardData): ClipboardItem {
  // We can only store text/html in the clipboard. Wrap the data into an HTML
  // element to be able to store it in the clipboard
  const content = JSON.stringify(data); // Escape the content to avoid XSS
  const base64Content = btoa(content);
  const htmlData = `<div data-mimetype="${DATA_CLIPBOARD_MIME}" data-content="${base64Content}" /></div>`;

  return new ClipboardItem({
    "text/html": new Blob([htmlData], { type: "text/html" })
  });
}

interface Props {
  // On image paste, should get an Asset
  onImage?: TransformerHooks["onImage"];
}

export function useClipboard(props: Props): [CopyFn, getDataFn, hasDataFn] {
  const { onImage } = props;
  const [data, setData] = useState<CopiedValueFallback>();
  const [hasData, setHasData] = useState<boolean>(false);

  // Store the data in the system clipboard
  const setClipboardData = useCallback(async function (data: ClipboardData): Promise<boolean> {
    // Use state as fallback
    setData(JSON.stringify(data));

    try {
      await navigator.clipboard.write([createClipboardItem(data)]);
      return true;
    } catch (error) {
      // ignore
      console.debug("Failed to write to clipboard", error);
    }

    return false;
  }, []);

  const getClipboardData = useCallback(
    async function (): Promise<ClipboardData | undefined> {
      try {
        const clipboardItems = await navigator.clipboard.read();
        const elements: GameElement[] = [];

        // Run through all clipboard items and use transformers
        // to convert them into game elements
        for (const clipboardItem of clipboardItems) {
          for (const type of clipboardItem.types) {
            const element = transformers[type]
              ? await transformers[type](await clipboardItem.getType(type), { onImage })
              : null;

            if (element && element.length > 0) {
              elements.push(...element);
            }
          }
        }

        if (elements.length > 0) {
          return { meta: { ts: Date.now() }, content: elements };
        }
      } catch (error) {
        // ignore
        console.debug("Failed to read from clipboard", error);
      }

      // If no elements were found, use the state as
      // fallback (emulated clipboard)
      return data ? (JSON.parse(data) as ClipboardData) : undefined;
    },
    [data, onImage]
  );

  const hasClipboardData = useCallback(async () => {
    try {
      const clipboardItems = await navigator.clipboard.read();
      const hasItems =
        clipboardItems.filter((item) => {
          return item.types.filter((type) => transformers[type]).length > 0;
        }).length > 0;

      if (hasItems) {
        return true;
      }
    } catch (error) {
      // ignore
      console.debug("Failed to read from clipboard", error);
    }

    // Fallback
    return !!data;
  }, [data]);

  const copy: CopyFn = useCallback(
    async (input: GameElement[], meta: Partial<Meta> = {}, options: Options = {}) => {
      const value: ClipboardData = { meta: { ...meta, ts: Date.now() }, content: input, pasteOnce: options.pasteOnce };
      return await setClipboardData(value);
    },
    [setClipboardData]
  );

  const getData: getDataFn = useCallback(async (): Promise<ClipboardData | null> => {
    const data = await getClipboardData();

    if (!data) {
      return null;
    }

    try {
      if (data.pasteOnce) {
        setData(null);
      }

      return { ...data, pasteOnce: data.pasteOnce };
    } catch (_error) {
      // ignore
    }

    return null;
  }, [getClipboardData]);

  useEffect(() => {
    const listener = async () => {
      hasClipboardData().then(setHasData);
    };

    document.addEventListener("paste", listener);
    document.addEventListener("copy", listener);
    document.addEventListener("cut", listener);
    document.addEventListener("focus", listener);

    // Initial check
    listener();

    return () => {
      document.removeEventListener("paste", listener);
      document.removeEventListener("copy", listener);
      document.removeEventListener("cut", listener);
      document.removeEventListener("focus", listener);
    };
  }, [hasClipboardData]);

  const checkHasData = useCallback(() => hasData, [hasData]);

  return [copy, getData, checkHasData];
}
