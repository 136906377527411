import { useEditor } from "@app/editor";

import { EditorButtonGroup } from "./EditorButtonGroup";
import { ToolbarButton } from "./ToolbarButton";

import NorthWestIcon from "@mui/icons-material/NorthWest";
import BackHandIcon from "@mui/icons-material/BackHand";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import FlipToBackIcon from "@mui/icons-material/FlipToBack";
import FlipToFrontIcon from "@mui/icons-material/FlipToFront";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import { useTranslation } from "@app/hooks";

interface Props {
  isTextEditing?: boolean;
  togglePan?: boolean;
  toggleScale?: boolean;
  onOrderSelection(direction: number): void;
}

export function CommonToolbarButtons(props: Props) {
  const { isTextEditing, togglePan, toggleScale, onOrderSelection } = props;

  const { currentLayer, setTool, editor } = useEditor();
  const { t } = useTranslation();

  const selectMode = !isTextEditing && editor?.tool === "select" && !togglePan && !toggleScale;
  const scaleMode = !isTextEditing && (editor?.tool === "scale" || (toggleScale && !togglePan));
  const panMode = editor?.tool === "pan" || togglePan;
  const hasSelection = editor.selection.length > 0;

  return (
    <>
      <EditorButtonGroup>
        <ToolbarButton
          active={selectMode}
          icon={<NorthWestIcon />}
          title={t("editor.autoTool")}
          onClick={() => setTool("select")}
        />
        <ToolbarButton
          active={scaleMode}
          icon={<ZoomOutMapIcon />}
          title={t("editor.scaleTool")}
          onClick={() => setTool("scale")}
        />
        <ToolbarButton
          active={panMode}
          icon={<BackHandIcon />}
          title={t("editor.panTool")}
          onClick={() => setTool("pan")}
        />
      </EditorButtonGroup>
      <EditorButtonGroup>
        <ToolbarButton
          icon={<UndoIcon />}
          title={t("editor.undo")}
          disabled={!currentLayer?.history.canUndo()}
          onClick={() => currentLayer?.history.undo()}
        />
        <ToolbarButton
          icon={<RedoIcon />}
          title={t("editor.redo")}
          disabled={!currentLayer?.history.canRedo()}
          onClick={() => currentLayer?.history.redo()}
        />
      </EditorButtonGroup>

      <EditorButtonGroup>
        <ToolbarButton
          icon={<FlipToFrontIcon />}
          title={t("editor.moveForward")}
          disabled={!hasSelection}
          onClick={() => onOrderSelection(1)}
        />
        <ToolbarButton
          icon={<FlipToBackIcon />}
          title={t("editor.moveBackward")}
          disabled={!hasSelection}
          onClick={() => onOrderSelection(-1)}
        />
      </EditorButtonGroup>
    </>
  );
}
