import { useCallback, useMemo, useState } from "react";

import {
  Alert,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
  styled,
} from "@mui/material";

import { withJsonFormsControlProps } from "@jsonforms/react";
import { ControlProps, RankedTester } from "@jsonforms/core";

import { FieldWrapper } from "../common/FieldWrapper";
import { MultiSectionModal, ModalSection } from "../common/MultiSectionModal";
import { ModalTabs, TabPanel } from "../common/ModalTabs";

import { isArrayOfType, isCustomControl, rankWith } from "../utils";

import AddIcon from "@mui/icons-material/Add";
import NorthEastIcon from "@mui/icons-material/NorthEast";
// import InventoryIcon from "@mui/icons-material/Inventory";
// import KeyIcon from "@mui/icons-material/Key";
// import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import DataObjectIcon from "@mui/icons-material/DataObject";
import ScoreboardIcon from "@mui/icons-material/Scoreboard";
import BlockIcon from "@mui/icons-material/Block";
import LinkIcon from "@mui/icons-material/Link";
import EditIcon from "@mui/icons-material/Edit";

const AdvancedForms = ["set-var"];

type ActionKey =
  | "none"
  | "go-to-level"
  // | "collect-item"
  // | "collect-key"
  // | "consume-clue"
  | "set-var"
  | "add-score"
  | "add-penalty"
  | "visit-url";
// | "cancel-game";

const labels: Record<ActionKey, string | null> = {
  none: null,
  "go-to-level": "Ir a escena",
  // "collect-item": "Recoger objeto",
  // "collect-key": "Recoger llave",
  // "consume-clue": "Consumir pista",
  "set-var": "Asignar variable",
  "add-score": "Sumar puntos",
  "add-penalty": "Restar puntos",
  "visit-url": "Visitar URL",
  // "cancel-game": "Cancelar juego",
};

const icons: Record<ActionKey, React.ReactNode | null> = {
  none: null,
  "go-to-level": <NorthEastIcon />,
  // "collect-item": <InventoryIcon />,
  // "collect-key": <KeyIcon />,
  // "consume-clue": <QuestionMarkIcon />,
  "set-var": <DataObjectIcon />,
  "add-score": <ScoreboardIcon />,
  "add-penalty": <BlockIcon />,
  "visit-url": <LinkIcon />,
  // "cancel-game": <ClearIcon />,
};

interface Action {
  command: ActionKey;
  payload: string;
}

interface Props extends ControlProps {
  data: Action[];
  path: string;
  handleChange: (path: string, value: Action[]) => void;
}

const Forms: Record<
  ActionKey,
  (
    value?: string,
    onChange?: (value: string) => void,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    config?: any
  ) => React.ReactElement
> = {
  none: () => (
    <Box>
      <Typography variant="h5">Selecciona una acción</Typography>
      <Typography variant="body1">
        Selecciona una accion del menu lateral para decidir qué hará el elemento
        seleccionado al ser accionado.
      </Typography>
    </Box>
  ),
  "go-to-level": (value, onChange, config) => (
    <Box>
      <Typography variant="body1">
        Introduce el nombre de la escena a la que se dirigirá el jugador al
        activar el elemento.
      </Typography>
      {config?.context?.editorContext?.gameDataInfo?.levels ? (
        <Select
          fullWidth
          displayEmpty
          aria-label="Escena"
          value={value}
          onChange={(e) => onChange?.(e.target.value)}
        >
          {config.context.editorContext.gameDataInfo.levels.map(
            (level: string) => (
              <MenuItem key={level} value={level}>
                {level}
              </MenuItem>
            )
          )}
        </Select>
      ) : (
        <TextField
          fullWidth
          label="Escena"
          value={value}
          onChange={(ev) => onChange?.(ev.target.value)}
        />
      )}
    </Box>
  ),
  // "collect-item": (value, onChange) => (
  //   <Box>
  //     <Typography variant="body1">
  //       Introduce el nombre del objeto de inventario que el jugador recogerá al
  //       activar el elemento.
  //     </Typography>
  //     <TextField
  //       fullWidth
  //       label="Objeto"
  //       value={value}
  //       onChange={(ev) => onChange?.(ev.target.value)}
  //     />
  //   </Box>
  // ),
  // "collect-key": (value, onChange) => (
  //   <Box>
  //     <Typography variant="body1">
  //       Introduce el nombre de la llave que el jugador recogerá al activar el
  //       elemento.
  //     </Typography>
  //     <TextField
  //       fullWidth
  //       label="Llave"
  //       value={value}
  //       onChange={(ev) => onChange?.(ev.target.value)}
  //     />
  //   </Box>
  // ),
  // "consume-clue": (value, onChange) => (
  //   <Box>
  //     <Typography variant="body1">
  //       Introduce el texto de la pista que el jugador consumirá al activar el
  //     </Typography>
  //     <TextField
  //       fullWidth
  //       label="Pista"
  //       value={value}
  //       onChange={(ev) => onChange?.(ev.target.value)}
  //       minRows={3}
  //     />
  //   </Box>
  // ),
  "set-var": (value, onChange) => (
    <Box>
      <Typography variant="body1">
        Las variables son valores que se pueden utilizar para controlar el flujo
        del juego mediante la activacion/desactivacion de otros elementos. Esto
        puede ser útil para crear puzzles o desafíos que requieran que el
        jugador realice ciertas acciones para poder avanzar.
      </Typography>
      <Typography variant="body1">
        Introduce una expresión que se evaluará para asignar el valor de la
        variable. Por ejemplo, si quieres que una variable "llave" sea "true"
        cuando el jugador recoge una llave, puedes usar la expresión:
      </Typography>
      <Typography variant="body2">
        <code>llave = true</code>
      </Typography>
      <TextField
        fullWidth
        label="Expresión"
        value={value}
        onChange={(ev) => onChange?.(ev.target.value)}
      />
    </Box>
  ),
  "add-score": (value, onChange) => (
    <Box>
      <Typography variant="body1">
        Introduce la cantidad de puntos que se sumarán al marcador del jugador
        al activar el elemento.
      </Typography>
      <TextField
        fullWidth
        type="number"
        label="Puntos"
        value={value}
        onChange={(ev) => onChange?.(ev.target.value)}
        onBlur={(ev) => {
          const score = parseInt(ev.target.value, 10);
          if (isNaN(score)) {
            onChange?.("");
          } else {
            onChange?.(Math.max(0, score).toString());
          }
        }}
      />
    </Box>
  ),
  "add-penalty": (value, onChange) => (
    <Box>
      <Typography variant="body1">
        Introduce la cantidad de puntos que se restarán al marcador del jugador
        al activar el elemento.
      </Typography>
      <TextField
        fullWidth
        type="number"
        label="Penalización"
        value={value}
        onChange={(ev) => onChange?.(ev.target.value)}
        onBlur={(ev) => {
          const score = parseInt(ev.target.value, 10);
          if (isNaN(score)) {
            onChange?.("");
          } else {
            onChange?.(Math.max(0, score).toString());
          }
        }}
      />
    </Box>
  ),
  "visit-url": (value, onChange) => (
    <Box>
      <Typography variant="body1">
        Abre una ventana nueva en el navegador con la URL que introduzcas. Por
        ejemplo: https://www.theufolab.com
      </Typography>
      <TextField
        fullWidth
        label="URL"
        value={value}
        onChange={(ev) => onChange?.(ev.target.value)}
        onBlur={(ev) => {
          const url = ev.target.value.match(/https?:\/\//)
            ? ev.target.value
            : `https://${ev.target.value}`;

          onChange?.(url);
        }}
      />
      <Alert severity="warning">
        <Typography variant="body2">
          <strong>Nota:</strong> La mayoría de navegadores bloquean las ventanas
          emergentes si estas se abren automáticamente y no por petición del
          usuario. Asegurate de que la acción configurada sea basada en una
          interacción del usuario.
        </Typography>
      </Alert>
    </Box>
  ),
};

const ActionList = styled(List)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(1),
}));

export function ActionPickerFieldRenderer({
  label,
  description,
  data = [],
  path,
  visible,
  uischema,
  handleChange,
  config,
}: Props) {
  const [currentAction, setCurrentAction] = useState<
    { command: ActionKey; payload: string; index?: number } | undefined
  >();

  const updateActions = useCallback(() => {
    if (!currentAction) return;

    const newActions = [...data];
    if (currentAction.index !== undefined) {
      newActions[currentAction.index] = {
        command: currentAction.command,
        payload: currentAction.payload,
      };
    } else {
      newActions.push({
        command: currentAction.command,
        payload: currentAction.payload,
      });
    }

    handleChange(path, newActions);
    setCurrentAction(undefined);
  }, [currentAction, data, handleChange, path]);

  const deleteCurrentAction = useCallback(() => {
    if (!currentAction || currentAction.index === undefined) return;

    const newActions = [...data];
    newActions.splice(currentAction.index, 1);

    handleChange(path, newActions);
    setCurrentAction(undefined);
  }, [currentAction, data, handleChange, path]);

  const currentActions: [ActionKey, string][] = useMemo(() => {
    return (
      data?.map((action) => {
        return [action.command, action.payload];
      }) || []
    );
  }, [data]);

  const advanced = !!uischema.options?.advanced;
  const forbiddenActions = uischema.options?.forbiddenActions || [];
  const canSave = currentAction?.command !== "none";

  const footer = useMemo(
    () => (
      <>
        {currentAction?.index !== undefined && (
          <Button
            variant="contained"
            color="error"
            onClick={deleteCurrentAction}
            disabled={!canSave}
          >
            Eliminar
          </Button>
        )}
        <Box display="flex" flexGrow={1} />
        <Button variant="outlined" onClick={() => setCurrentAction(undefined)}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={updateActions}
          disabled={!canSave}
        >
          {currentAction?.index !== undefined ? "Guardar" : "Añadir"}
        </Button>
      </>
    ),
    [canSave, currentAction?.index, deleteCurrentAction, updateActions]
  );

  if (!visible) {
    return null;
  }

  return (
    <>
      <FieldWrapper label={label} description={description} uiSchema={uischema}>
        <ActionList>
          {currentActions &&
            currentActions.map(([command, payload], index) => (
              <ListItem key={index}>
                <ListItemIcon>{icons[command]}</ListItemIcon>
                <ListItemText sx={{ flexGrow: 1 }}>
                  {labels[command] || command} "<strong>{payload}</strong>"
                </ListItemText>
                <IconButton
                  onClick={() => setCurrentAction({ command, payload, index })}
                >
                  <EditIcon />
                </IconButton>
              </ListItem>
            ))}
          {!currentActions.length && (
            <ListItem>
              <ListItemText sx={{ textAlign: "center" }}>
                No hay acciones
              </ListItemText>
            </ListItem>
          )}
        </ActionList>
        <Button
          fullWidth
          onClick={() => setCurrentAction({ command: "none", payload: "" })}
          color="primary"
          variant="contained"
          startIcon={<AddIcon />}
        >
          Añadir una acción
        </Button>
      </FieldWrapper>
      <MultiSectionModal
        open={currentAction !== undefined}
        onClose={() => setCurrentAction(undefined)}
        footer={footer}
      >
        <ModalSection
          id="main"
          title={`Editar accion: ${(currentAction && labels[currentAction?.command]) || "Selecciona..."}`}
          withSideTabs
        >
          <ModalTabs
            activeTab={currentAction?.command}
            onTabChange={(command) =>
              setCurrentAction({
                command,
                payload: "",
                index: currentAction?.index,
              })
            }
          >
            {Object.entries(labels)
              .filter(
                ([command]) =>
                  advanced ||
                  (!AdvancedForms.includes(command) &&
                    !forbiddenActions.includes(command))
              )
              .map(([command, label]) => (
                <TabPanel
                  key={command}
                  id={command}
                  title={label}
                  icon={icons[command as ActionKey]}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                      gap: 2,
                      justifyContent: "space-between",
                    }}
                  >
                    {Forms[command as ActionKey]?.(
                      currentAction?.payload,
                      (payload) => {
                        setCurrentAction({
                          command: command as ActionKey,
                          payload,
                          index: currentAction?.index,
                        });
                      },
                      config
                    )}
                  </Box>
                </TabPanel>
              ))}
          </ModalTabs>
        </ModalSection>
      </MultiSectionModal>
    </>
  );
}

const tester: RankedTester = rankWith(20, (uiSchema, schema, context) => {
  return (
    isCustomControl(uiSchema, "ActionPicker2") &&
    isArrayOfType("object", uiSchema, schema, context)
  );
});

export default {
  tester,
  renderer: withJsonFormsControlProps(ActionPickerFieldRenderer),
};
