import { useParams } from "@app/router";
import { useState, useEffect, useCallback, useMemo, useRef } from "react";

import { DEFAULT_THEME, type GameDataInterface } from "@shared/game-engine";
import { type GameDefinitionDto } from "@shared/api-client";
import { GamePlayerOffline, RequireInteractionBoundary, FullScreenBoundary } from "@shared/game-player";
import { type RenderingContext } from "@shared/game-player/types/canvas";

import { deobfuscate } from "@shared/utils/obfuscator";

import { useAppContext, useBackend } from "@app/hooks";

import "./Player.css";
import { useLocation } from "react-router-dom";

function PlayerPreview() {
  const { uuid, version } = useParams("/preview/:uuid/:version");
  const { search } = useLocation();
  const { player } = useBackend();
  const { notify } = useAppContext();

  const viewportRef = useRef<HTMLDivElement>(null);
  const [error, setError] = useState<Error | null>();
  const [game, setGame] = useState<GameDefinitionDto | null>();
  const [gameData, setGameData] = useState<GameDataInterface | null>();
  const [level, setLevel] = useState<string | undefined>();

  const [ready, setReady] = useState(false);
  const [renderingContext, setRenderingContext] = useState<Partial<RenderingContext>>();

  useEffect(() => {
    try {
      const params = new URLSearchParams(search);
      const targetLevel = params.get("level");

      if (targetLevel) {
        setLevel(targetLevel);
        notify(`Previsualizando nivel "${targetLevel}"`);
      }
    } catch (e) {
      // ignore
    }
  }, [search, notify]);

  const loadGame = useCallback(
    async (uuid: string) => {
      player
        .get({ path: "/g/:uuid", params: { uuid } })
        .then(async (game) => {
          setGame(game);

          const gameData = await player.get({
            path: "/g/:uuid/data",
            params: { uuid },
            search: { version }
          });

          const parsedGameData = JSON.parse(deobfuscate(gameData.d, uuid)) as GameDataInterface;
          setGameData(parsedGameData);

          // Apply theme
          // TODO: Use a component
          document.body.style.backgroundColor =
            parsedGameData.theme?.background || DEFAULT_THEME.background || "#FFFFFF";
          document.body.style.color = parsedGameData.theme?.text || DEFAULT_THEME.text || "#000000";
        })
        .catch(setError);
    },
    [player, version]
  );

  /* Handle window resize */
  useEffect(() => {
    if (!gameData?.screen || !viewportRef.current) return;

    const updateScale = () => {
      if (!gameData?.screen || !viewportRef.current) return;

      const bounds = viewportRef.current.getBoundingClientRect();
      const scale = Math.min(bounds.height / gameData.screen.height, bounds.width / gameData.screen.width);

      setRenderingContext({
        viewportWidth: bounds.width,
        viewportHeight: bounds.height,
        canvasX: bounds.width / 2 - (gameData.screen.width * scale) / 2,
        canvasY: bounds.height / 2 - (gameData.screen.height * scale) / 2,
        canvasScale: scale
      });
    };

    updateScale();
    const interval = setInterval(updateScale, 500);

    // Observe the viewport for changes
    const observer = new ResizeObserver(updateScale);
    observer.observe(viewportRef.current);

    return () => {
      clearInterval(interval);
      observer.disconnect();
    };
  }, [gameData?.screen, ready]);

  // Game load
  useEffect(() => {
    if (uuid) {
      loadGame(uuid);
    }
  }, [uuid, loadGame]);

  const playerInnerStyles = useMemo(
    () => ({
      width: gameData?.screen?.width,
      height: gameData?.screen?.height,
      transform: `translate(-50%, -50%) scale(${renderingContext?.canvasScale ?? 1})`
    }),
    [gameData?.screen?.height, gameData?.screen?.width, renderingContext?.canvasScale]
  );

  const playerSettings = useMemo(
    () => ({
      assetBaseUrl: game?.assetBaseUrl || "/",
      pluginBaseUrl: import.meta.env.VITE_PLUGIN_BASE_URL,
      builtinPluginBaseUrl: import.meta.env.VITE_PLUGIN_BUILTIN_BASE_URL,
      pluginIndexFile: import.meta.env.VITE_PLUGIN_INDEX_FILE,
      strictMode: true
    }),
    [game]
  );

  const gameInitialState = useMemo(() => (level ? { currentLevel: level } : undefined), [level]);

  if (error) {
    throw error;
  }

  if (!game || !gameData) {
    return <div className='loading'>Loading...</div>;
  }

  return (
    <FullScreenBoundary
      requireFullScreen={gameData.screen?.fullScreen}
      requireOrientation={gameData.screen?.orientation}
      fullScreenWarning={(doFullScreen) => (
        <div className='full-screen-warning' onClick={doFullScreen}>
          <p>Click to enter fullscreen</p>
          <div className='icon' />
        </div>
      )}
      orientationWarning={
        <div className='full-screen-warning'>
          <p>Please rotate your device</p>
          <div className='icon' />
        </div>
      }
    >
      <div className='game-player' draggable={false} ref={viewportRef}>
        <div className='game-player-inner' style={playerInnerStyles}>
          <RequireInteractionBoundary
            enabled={false} // Disable the warning for now
            warning={(play) => (
              <div
                className='full-screen-warning'
                onClick={() => {
                  setReady(true);
                  play();
                }}
              >
                Click to start
              </div>
            )}
          >
            <GamePlayerOffline
              initialState={gameInitialState}
              gameData={gameData}
              settings={playerSettings}
              renderingContext={renderingContext}
            />
          </RequireInteractionBoundary>
        </div>
      </div>
    </FullScreenBoundary>
  );
}

export default PlayerPreview;
