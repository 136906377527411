import { type ReactElement, type SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";
import cloneDeep from "lodash/cloneDeep";

import { Form, type JsonSchema, type JsonUISchema } from "@shared/form-builder";
import { ModalSection, MultiSectionModal } from "@shared/form-builder/common/MultiSectionModal";

import { type PluginManifest } from "@shared/utils/plugins";
import {
  type GameDataInterface,
  SUPPORTED_LANGUAGES,
  type GameElement,
  convertLegacyActions,
  GameElementCategory,
  type GameActionsMap,
  type TransitionSettings,
  type Translations,
  DEFAULT_TRANSITIONS
} from "@shared/game-engine";

import { AssetPickerModal, type AssetPickerModalProps, ToolbarButton, useEditor } from "@app/editor";
import { type OneOf } from "@app/types";
import { getTransitionsSchema, getTransitionsUISchema } from "@app/schemas/transitions.schema";
import { getTranslationsSchema, getTranslationsUISchema } from "@app/schemas/translations.schema";
import { getElementPropertiesSchema, getElementPropertiesUISchema } from "@app/schemas/element.properties.schema";
import { getActionsSchema, getActionsUISchema } from "@app/schemas/actions.schema";
import { useAppContext, useTranslation } from "@app/hooks";

import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";

import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import FormatFontIcon from "@mui/icons-material/Title";
import FormatFontSizeIcon from "@mui/icons-material/FormatSize";
import OpacityIcon from "@mui/icons-material/Opacity";
import ForegroundColor from "@mui/icons-material/FormatColorText";
import BackgroundColor from "@mui/icons-material/FormatColorFill";
import SettingsIcon from "@mui/icons-material/Settings";
import AnimationIcon from "@mui/icons-material/Animation";
import ActionsIcon from "@mui/icons-material/AdsClick";
import StrokeStyleIcon from "@mui/icons-material/LineStyle";
import StrokeWidthIcon from "@mui/icons-material/LineWeight";
import ControlCameraIcon from "@mui/icons-material/ControlCamera";
import SettingsOverscanIcon from "@mui/icons-material/SettingsOverscan";
import LanguageIcon from "@mui/icons-material/Language";

import { EditorButtonGroup } from "./EditorButtonGroup";
import { InputColorControl } from "./Controls/InputColorControl";
import { InputNumberControl } from "./Controls/InputNumberControl";
import { DropdownControl } from "./Controls/DropdownControl";

import { ToolbarButtonModalForm } from "./ToolbarButtonModalForm";
import { Button } from "@mui/material";

interface Props {
  element: GameElement;
  manifest: PluginManifest;
  onUpdate: (element: Partial<GameElement>) => void;
  advanced?: boolean;
  forceOpen?: string;
  onCloseProperties?: () => void;
}

type TextAlign = "left" | "center" | "right" | "justify";
type Positioning =
  | "top left"
  | "top center"
  | "top right"
  | "center left"
  | "center center"
  | "center right"
  | "bottom left"
  | "bottom center"
  | "bottom right";

const alignIconsMap: Record<TextAlign, ReactElement> = {
  left: <FormatAlignLeftIcon />,
  center: <FormatAlignCenterIcon />,
  right: <FormatAlignRightIcon />,
  justify: <FormatAlignJustifyIcon />
};

type PropertiesWithRoot<T> = T & {
  _root: GameElement<T>; // deprecated
  __element: GameElement<T>;
  __game?: GameDataInterface;
  _updatedAt?: number;
};

type ActionsSettings = Pick<GameElement, "actions" | "condition" | "_updatedAt">;

interface GameElementWithRoot<T> extends GameElement<T> {
  properties: PropertiesWithRoot<T>;
}

const calculatePropertiesData = function <T>(
  element: GameElement<T>,
  gameData?: GameDataInterface
): GameElementWithRoot<T> {
  const clone = cloneDeep(element);

  // Normalize actions. Convert legacy actions to the new format
  const actions = Array.isArray(clone.actions) ? convertLegacyActions(clone) : clone.actions;

  return {
    ...clone,
    actions,

    properties: {
      ...clone.properties,

      // Hack. Add the _root to allow the properties form
      // to be able to edit the root properties. _gamedata
      // is also added to allow the properties form to access
      // the game data.
      _updatedAt: clone._updatedAt,
      _root: clone, // deprecated
      __element: clone,
      __game: gameData
    }
  };
};

export function ComponentToolbarButtons(props: Props) {
  // To be used within the form context
  const editorContext = useEditor();
  const { notify, confirm } = useAppContext();

  const { element, manifest, onUpdate, forceOpen, advanced, onCloseProperties } = props;
  const { toolbar } = manifest || {};
  const { t, ts } = useTranslation();

  const [propertiesSchema, setPropertiesSchema] = useState<JsonSchema>();
  const [propertiesUISchema, setPropertiesUISchema] = useState<JsonUISchema | false>();
  const [assetPickerOpen, setAssetPickerOpen] = useState<Partial<AssetPickerModalProps>>();
  const [propertiesOpen, setPropertiesOpen] = useState(false);
  const [propertiesSection, setPropertiesSection] = useState<string>();

  const [currentProperties, setCurrentProperties] = useState<PropertiesWithRoot<unknown>>();
  const [currentTransitions, setCurrentTransitions] = useState<TransitionSettings>(DEFAULT_TRANSITIONS);
  const [currentActions, setCurrentActions] = useState<ActionsSettings>({});
  const [isDirty, setIsDirty] = useState(false);

  const handlePropertiesUpdate = useCallback((data: PropertiesWithRoot<unknown>) => {
    setCurrentProperties(data);
    setIsDirty(true);
  }, []);

  const handleTransitionsUpdate = useCallback((data: TransitionSettings) => {
    setCurrentTransitions(data);
    setIsDirty(true);
  }, []);

  const handleActionsUpdate = useCallback((data: ActionsSettings) => {
    setCurrentActions(data);
    setIsDirty(true);
  }, []);

  const getValue = useCallback(
    function <T>(path: string): T {
      return path
        .replace(/^#\//, "")
        .split(/\//g)
        .reduce((acc, key) => {
          return acc && typeof acc === "object" ? acc[key] : undefined;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }, element as any);
    },
    [element]
  );

  const getSchema = useCallback(
    function <T>(path: string): T {
      return path
        .replace(/^#\//, "")
        .split(/\//g)
        .reduce((acc, key) => {
          return acc && typeof acc === "object" ? acc[key] : undefined;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }, manifest.schema as any);
    },
    [manifest.schema]
  );

  const openProperties = useCallback((section: string) => {
    setPropertiesSection(section);
    setPropertiesOpen(true);
  }, []);

  const handleCloseProperties = useCallback(
    async (ev?: SyntheticEvent) => {
      // ev is a modal close or cancel event
      // if not set, it's a manual close
      if (isDirty && ev) {
        await confirm({
          title: t("common.unsavedChanges"),
          content: t("common.unsavedChangesContent")
        });
      }

      setPropertiesOpen(false);
      onCloseProperties?.();
    },
    [confirm, isDirty, onCloseProperties, t]
  );

  // Merge all together and submit
  const handleSubmit = useCallback(async () => {
    const updates: Partial<GameElement> = {};

    if (currentProperties) {
      const { __game, _root, __element, ...properties } = currentProperties;
      Object.assign(updates, { ..._root, ...__element, properties });

      // Update game data if needed
      if (__game) {
        editorContext.updateGameData(__game);
      }
    }

    if (currentTransitions) {
      Object.assign(updates, { transitions: currentTransitions });
    }

    if (currentActions) {
      Object.assign(updates, currentActions);
    }

    onUpdate(updates);
    setIsDirty(false);
    handleCloseProperties();

    return true;
  }, [currentActions, currentProperties, currentTransitions, editorContext, handleCloseProperties, onUpdate]);

  const bold = toolbar?.bold ? getValue<boolean>(toolbar.bold) : null;
  const italic = toolbar?.italic ? getValue<boolean>(toolbar.italic) : null;
  const underline = toolbar?.underline ? getValue<boolean>(toolbar.underline) : null;
  const textAlign = toolbar?.textAlign ? getValue<string>(toolbar.textAlign) : null;
  const fontSize = toolbar?.fontSize ? getValue<string>(toolbar.fontSize) : null;
  const fontFamily = toolbar?.fontFamily ? getValue<string>(toolbar.fontFamily) : null;
  const fgColor = toolbar?.fgColor ? getValue<string>(toolbar.fgColor) : null;
  const bgColor = toolbar?.bgColor ? getValue<string>(toolbar.bgColor) : null;
  const strokeWidth = toolbar?.strokeWidth ? getValue<number>(toolbar.strokeWidth) : null;
  const strokeStyle = toolbar?.strokeStyle ? getValue<string>(toolbar.strokeStyle) : null;
  const positioning = toolbar?.positioning ? getValue<Positioning>(toolbar.positioning) : null;
  const fitting = toolbar?.fitting ? getValue<string>(toolbar.fitting) : null;

  const hasProperties = currentProperties && propertiesSchema && propertiesUISchema !== false;
  const hasTranslations = !!manifest?.features.translationFields?.length;
  const hasActions = Object.keys(manifest?.actions || {}).length > 0 && !!currentActions;

  const supportedTriggers = manifest.actions || {};

  const doUpdate = useCallback(
    function (path: string, value: unknown) {
      const keys: string[] = path.replace(/^#\//, "").split(/\//g);
      const updates = cloneDeep(element);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let current: any = updates;
      const lastKey = keys.pop();

      if (!lastKey) {
        // nowhere to assign!
        return;
      }

      keys.forEach((key) => {
        if (!current[key]) {
          current[key] = {};
        }
        current = current[key];
      });
      current[lastKey] = value;

      // We only care about properties and stage
      onUpdate({ properties: updates.properties, stage: updates.stage });
    },
    [element, onUpdate]
  );

  const handleCloseAssetPicker = useCallback(() => setAssetPickerOpen(undefined), []);

  const handleUploadAsset = useCallback(
    (files: File[]) => {
      editorContext.uploadAssets(files).then(() => {
        editorContext.reloadAssets();
        notify(t("common.uploadSuccess"), "success");
      });
    },
    [editorContext, notify, t]
  );

  const handleTranslationsSubmit = useCallback(
    async (data: Translations) => {
      onUpdate({ translations: data });
      return true;
    },
    [onUpdate]
  );

  // Update properties (dirty cleanup is implicit in the next useEffect)
  useEffect(() => {
    setCurrentProperties(calculatePropertiesData(element, editorContext.gameData).properties);
    setPropertiesSchema(getElementPropertiesSchema(manifest, element, editorContext.gameDataInfo, t));
    setPropertiesUISchema(getElementPropertiesUISchema(manifest, t));
  }, [element, manifest, editorContext.gameDataInfo, t, editorContext.gameData, propertiesOpen]);

  // Translate legacy actions and update transitions
  useEffect(() => {
    if (!element.actions) {
      setCurrentActions({});
    } else {
      const actionMap: GameActionsMap =
        Array.isArray(element.actions) || typeof element.actions === "string"
          ? convertLegacyActions(element)
          : element.actions;

      // Map legacy actions
      if (actionMap.legacy && actionMap.legacy.length > 0) {
        if (element.type === GameElementCategory.LOCK || element.type === GameElementCategory.MINIGAME) {
          // Legacy is mapped to "success"
          actionMap.success = actionMap.legacy;
          delete actionMap.legacy;
        } else {
          // Legacy is mapped to "click"
          actionMap.click = actionMap.legacy;
          delete actionMap.legacy;
        }
      }

      setCurrentActions({ actions: actionMap, condition: element.condition, _updatedAt: element._updatedAt });
    }

    setCurrentTransitions(element.transitions || DEFAULT_TRANSITIONS);
    setIsDirty(false);
  }, [element, propertiesOpen]);

  useEffect(() => {
    if (forceOpen) {
      setPropertiesOpen(true);
      setPropertiesSection(forceOpen);
    }
  }, [forceOpen]);

  const formContext = useMemo(
    () => ({
      manifest,
      t,
      editorContext,
      modals: {
        setAssetPickerOpen
      }
    }),
    [manifest, t, editorContext]
  );

  return (
    <>
      {/* Component controls */}
      <EditorButtonGroup>
        {hasProperties && (
          <ToolbarButton
            icon={<SettingsIcon color='action' />}
            title={ts("editor.properties")}
            onClick={() => openProperties("properties")}
          />
        )}
        {(hasActions || advanced) && (
          <ToolbarButton
            icon={<ActionsIcon color='error' />}
            title={ts("editor.actions")}
            onClick={() => openProperties("actions")}
          />
        )}
        <ToolbarButton
          icon={<AnimationIcon color='success' />}
          title={ts("editor.elementEffects")}
          onClick={() => openProperties("transitions")}
        />
      </EditorButtonGroup>

      <MultiSectionModal
        open={propertiesOpen}
        onClose={handleCloseProperties}
        openSection={propertiesSection}
        footer={
          <>
            <Button onClick={handleCloseProperties} variant='contained' color='info'>
              {isDirty ? t("common.cancel") : t("common.close")}
            </Button>
            <Button onClick={handleSubmit} variant='contained' disabled={!isDirty}>
              {t("common.apply")}
            </Button>
          </>
        }
        alwaysRender
      >
        {hasProperties ? (
          <ModalSection
            id='properties'
            title={t("editor.propertiesModalTitle", { name: manifest.name })}
            icon={<SettingsIcon />}
            withSideTabs
          >
            <Form
              data={currentProperties}
              schema={propertiesSchema}
              uiSchema={propertiesUISchema}
              onChange={handlePropertiesUpdate}
              contextData={formContext}
            />
          </ModalSection>
        ) : null}
        {hasActions ? (
          <ModalSection id='actions' title={ts("editor.actions")} icon={<ActionsIcon />} withSideTabs>
            <Form
              data={currentActions}
              schema={getActionsSchema(supportedTriggers)}
              uiSchema={getActionsUISchema(supportedTriggers, [], advanced)}
              onChange={handleActionsUpdate}
              contextData={formContext}
            />
          </ModalSection>
        ) : null}
        <ModalSection id='transitions' title={ts("editor.elementEffects")} icon={<AnimationIcon />} withSideTabs>
          <Form
            data={currentTransitions}
            schema={getTransitionsSchema()}
            uiSchema={getTransitionsUISchema()}
            onChange={handleTransitionsUpdate}
            contextData={formContext}
          />
        </ModalSection>
      </MultiSectionModal>

      {/* Translations controls */}
      {hasTranslations && (
        <EditorButtonGroup>
          <ToolbarButtonModalForm
            icon={<LanguageIcon />}
            buttonTitle={ts("editor.translations")}
            modalTitle={t("editor.translationsModalTitle")}
            data={element.translations || {}}
            schema={getTranslationsSchema(manifest?.features.translationFields || [], SUPPORTED_LANGUAGES)}
            uiSchema={getTranslationsUISchema(manifest?.features.translationFields || [], SUPPORTED_LANGUAGES)}
            onSubmit={handleTranslationsSubmit}
            formContext={formContext}
          />
        </EditorButtonGroup>
      )}

      {/* Color & style controls */}
      <EditorButtonGroup>
        <InputNumberControl
          icon={<OpacityIcon />}
          label={t("editor.opacity")}
          min={0}
          max={100}
          step={1}
          multiplier={100}
          value={element.stage.opacity}
          onChange={(value) => doUpdate("#/stage/opacity", value)}
          withSlider
        />
        {toolbar && fgColor !== null && (
          <InputColorControl
            icon={<ForegroundColor />}
            label={t("editor.foregroundColor")}
            value={fgColor}
            onChange={(value) => doUpdate(toolbar.fgColor!, value)}
            inputOnFocus
          />
        )}
        {toolbar && bgColor !== null && (
          <InputColorControl
            icon={<BackgroundColor />}
            label={t("editor.backgroundColor")}
            value={bgColor}
            onChange={(value) => doUpdate(toolbar.bgColor!, value)}
            inputOnFocus
          />
        )}
        {toolbar && strokeWidth !== null && (
          <InputNumberControl
            icon={<StrokeWidthIcon />}
            label={t("editor.strokeWidth")}
            min={0}
            max={50}
            step={1}
            value={strokeWidth}
            onChange={(value) => doUpdate(toolbar.strokeWidth!, value)}
            withSlider
          />
        )}
        {toolbar && strokeStyle !== null && (
          <DropdownControl
            label={t("editor.strokeStyle")}
            icon={<StrokeStyleIcon />}
            value={strokeStyle}
            items={getSchema<OneOf[]>(`${toolbar.strokeStyle}/oneOf`).map(({ const: value, title: label }) => ({
              value,
              label
            }))}
            onChange={(value) => doUpdate(toolbar.strokeStyle!, value)}
          />
        )}
      </EditorButtonGroup>

      {/* Text controls */}
      {toolbar &&
        (bold !== null ||
          italic !== null ||
          underline !== null ||
          fontFamily !== null ||
          fontSize !== null ||
          textAlign !== null) && (
          <EditorButtonGroup>
            {bold !== null && (
              <ToolbarButton
                active={!!bold}
                icon={<FormatBoldIcon />}
                title={t("editor.bold")}
                onClick={() => doUpdate(toolbar.bold!, !bold)}
              />
            )}
            {italic !== null && (
              <ToolbarButton
                active={!!italic}
                icon={<FormatItalicIcon />}
                title={t("editor.italic")}
                onClick={() => doUpdate(toolbar.italic!, !italic)}
              />
            )}
            {underline !== null && (
              <ToolbarButton
                active={!!underline}
                icon={<FormatUnderlinedIcon />}
                title={t("editor.underline")}
                onClick={() => doUpdate(toolbar.underline!, !underline)}
              />
            )}
            <DropdownControl
              label={t("editor.textAlign")}
              icon={alignIconsMap[textAlign as TextAlign]}
              value={textAlign as TextAlign}
              items={getSchema<OneOf[]>(`${toolbar.textAlign}/oneOf`).map(({ const: align }) => ({
                value: align,
                label: alignIconsMap[align as TextAlign]
              }))}
              onChange={(value) => doUpdate(toolbar.textAlign!, value)}
              noSelectionText
            />
            {fontFamily !== null && (
              <DropdownControl
                label={t("editor.fontFamily")}
                icon={<FormatFontIcon />}
                value={fontFamily}
                items={getSchema<string[]>(`${toolbar.fontFamily}/enum`).map((font) => ({
                  value: font,
                  sx: { fontFamily: font }
                }))}
                onChange={(value) => doUpdate(toolbar.fontFamily!, value)}
              />
            )}
            {fontSize !== null && (
              <InputNumberControl
                icon={<FormatFontSizeIcon />}
                label={t("editor.fontSize")}
                min={10}
                max={100}
                step={1}
                value={Number(fontSize?.toString().replace(/[^\d]+$/, ""))}
                onChange={(value) => doUpdate(toolbar.fontSize!, `${value}pt`)}
                withSlider
              />
            )}
          </EditorButtonGroup>
        )}

      {/* Positioning controls */}
      {toolbar && (positioning !== null || fitting !== null) && (
        <EditorButtonGroup>
          {positioning !== null && (
            <DropdownControl
              label={t("editor.positioning")}
              icon={<ControlCameraIcon />}
              value={positioning}
              items={getSchema<OneOf[]>(`${toolbar.positioning}/oneOf`).map(({ const: value, title: label }) => ({
                value,
                label
              }))}
              onChange={(value) => doUpdate(toolbar.positioning!, value)}
            />
          )}
          {fitting !== null && (
            <DropdownControl
              label={t("editor.fitting")}
              icon={<SettingsOverscanIcon />}
              value={fitting}
              items={getSchema<OneOf[]>(`${toolbar.fitting}/oneOf`).map(({ const: value, title: label }) => ({
                value,
                label
              }))}
              onChange={(value) => doUpdate(toolbar.fitting!, value)}
            />
          )}
        </EditorButtonGroup>
      )}

      <AssetPickerModal
        {...assetPickerOpen}
        open={!!assetPickerOpen}
        onClose={handleCloseAssetPicker}
        onUpload={handleUploadAsset}
      />
    </>
  );
}
