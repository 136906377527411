import { v5 as uuidv5 } from "uuid";
import cloneDeep from "lodash/cloneDeep";

import { GameDataInterface, GameElement } from "../types";
import { GameData } from "../GameData";
import {
  DEFAULT_ELEMENT,
  DEFAULT_GAMEFLOW,
  DEFAULT_LEVEL,
  DEFAULT_SCORING,
  DEFAULT_SCREEN,
  DEFAULT_THEME,
  DEFAULT_TIMER,
} from "../consts";

/**
 * Generate a unique id for a given element
 *
 * @param elementId The element id
 * @param r A salt
 * @returns
 */
export function euid(elementId: string, r: string = ""): string {
  const str = [r, elementId].join("-");
  return uuidv5(str, uuidv5.URL);
}

/**
 * Get the default element data
 *
 * @returns
 */
export const getDefaultElement = (): GameElement => cloneDeep(DEFAULT_ELEMENT);

/**
 * Get the default game data
 *
 * @returns
 */
export const getDefaultGameData = (): GameDataInterface =>
  cloneDeep({
    version: 1,
    updatedAt: new Date(),

    screen: DEFAULT_SCREEN,
    theme: DEFAULT_THEME,
    gameflow: DEFAULT_GAMEFLOW,
    timer: DEFAULT_TIMER,
    items: {},
    clues: {},
    scoring: DEFAULT_SCORING,
    levels: {
      inicio: DEFAULT_LEVEL,
      fin: DEFAULT_LEVEL,
      gameover: DEFAULT_LEVEL,
    },
    overlays: {
      principal: DEFAULT_LEVEL,
    },
    translations: {},
  });

/**
 * "Render" an element, converting it for being used in the game
 *
 * @param game The game data
 * @param levelId The level id
 * @param element The element to convert
 * @param index current index of the element
 * @param r A salt for randomization of the ids
 * @returns
 */
export function renderElement(
  game: GameData,
  levelId: string,
  element: GameElement,
  r?: string
): GameElement {
  const { enabled, id = "", type, layout, ...rest } = element;

  return {
    id: r ? euid(id, r) : id,
    enabled: typeof enabled === "boolean" ? enabled : true,
    type,
    layout:
      layout &&
      layout.map((element) => renderElement(game, levelId, element, r)),
    ...rest,
  };
}

/**
 * Find an element deeply by a custom matcher
 *
 * @param layout The layout
 * @param matcher  The matcher
 * @returns The element or undefined if not found
 */
export function findElement(
  layout: GameElement[] | undefined,
  matcher: (element: GameElement) => boolean
): GameElement | undefined {
  if (!layout) {
    return;
  }

  for (const element of layout) {
    if (matcher(element)) return element;
    const found = findElement(element.layout, matcher);
    if (found) return found;
  }
}

/**
 * Find all the elements deeply in a layout that match a custom matcher
 *
 * @param layout The layout
 * @param matcher The matcher
 * @returns The elements that match the matcher
 */
export function findAllLayoutElements(
  layout: GameElement[],
  matcher: (element: GameElement) => boolean
): GameElement[] {
  return layout.reduce((prev, element) => {
    const output: GameElement[] = [...prev];

    if (matcher(element)) {
      output.push(element);
    }

    if (element.layout) {
      findAllLayoutElements(element.layout, matcher).forEach((el) =>
        output.push(el)
      );
    }

    return output;
  }, [] as GameElement[]);
}

/**
 * Find all the elements in a layout by ID
 *
 * @param layout
 * @param ids
 * @returns
 */
export function findElementsById(
  layout: GameElement[],
  ids: string[]
): GameElement[] {
  return findAllLayoutElements(layout, (element) => ids.includes(element.id));
}

/**
 * Find an element by ID
 *
 * @param layout
 * @param id
 * @returns
 */
export function findElementById(
  layout: GameElement[],
  id: string
): GameElement | undefined {
  return findElement(layout, (element) => element.id === id);
}

/**
 * Replace a value in an object or array recursively. It will find the exact match.
 * Useful for replacing Asset references: asset://.....
 *
 * The keyMatcher can be a string or a function. If it is a string, it will match the key.
 * If it is a function, it will match the key and the parent object.
 *
 * The keyMatcher can also be a string starting with a *, in which case it will match the end of the path.
 *
 * @param data
 * @param oldValue
 * @param newValue
 * @returns
 */
export function replaceValue<T>(
  data: T,
  oldValue: string,
  newValue: string,
  keyMatcher?:
    | string
    | RegExp
    | ((key: string, obj: Record<string, unknown>, path: string) => boolean),
  currentPath = "",
  parent?: Record<string, unknown>
): T {
  // Shortcut
  if (data === null || data === undefined) {
    return data;
  }

  // Iterate arrays
  if (Array.isArray(data)) {
    return data.map((item) =>
      // Do not modify the path for arrays
      replaceValue(
        item,
        oldValue,
        newValue,
        keyMatcher,
        `${currentPath}[]`,
        parent
      )
    ) as unknown as T;
  }

  // Iterate objects
  if (typeof data === "object") {
    const updatedData = { ...data };

    for (const currentKey in updatedData) {
      Object.assign(updatedData, {
        [currentKey]: replaceValue(
          updatedData[currentKey],
          oldValue,
          newValue,
          keyMatcher,
          currentPath ? `${currentPath}.${currentKey}` : currentKey,
          updatedData as Record<string, unknown>
        ),
      });
    }

    return updatedData;
  }

  if (typeof data === "string" && data === oldValue) {
    const pathParts = currentPath.split(/\./g);
    const currentKey = pathParts[pathParts.length - 1];

    // Check if the key matches the keyMatcher
    if (keyMatcher) {
      const currentKeyMatch =
        typeof keyMatcher === "string" && currentKey === keyMatcher;
      const currentPathMatch =
        typeof keyMatcher === "string" && currentPath === keyMatcher;
      const partialMatch =
        typeof keyMatcher === "string" && keyMatcher?.includes("*")
          ? currentPath.match(new RegExp(`^${keyMatcher.replace("*", ".*")}$`))
          : false;
      const regExpMatch =
        keyMatcher instanceof RegExp && keyMatcher.test(currentPath);
      const fnMatch =
        typeof keyMatcher === "function" &&
        keyMatcher(currentKey, parent || {}, currentPath);

      if (
        !currentKeyMatch &&
        !currentPathMatch &&
        !partialMatch &&
        !fnMatch &&
        !regExpMatch
      ) {
        return data;
      }
    }

    return newValue as T;
  }

  // Number, boolean, etc.
  // Doesn't need to be replaced

  return data;
}

/**
 * Find a value deeply in an object or array
 *
 * @param data
 * @param matcher
 * @param path
 * @returns The values that match the matcher
 */
export function findDeep<T = unknown>(
  data: unknown,
  matcher: (obj: unknown, path: string) => boolean,
  path = ""
): T[] {
  if (matcher(data, path)) {
    return [data as T];
  }

  const results: T[] = [];

  if (Array.isArray(data)) {
    for (let i = 0; i < data.length; i++) {
      const result = findDeep<T>(data[i], matcher, `${path}[${i}]`);
      if (result) {
        results.push(...result);
      }
    }
  } else if (typeof data === "object") {
    for (const key in data) {
      const result = findDeep<T>(
        data[key as never],
        matcher,
        path ? `${path}.${key}` : key
      );

      if (result) {
        results.push(...result);
      }
    }
  }

  return results;
}
