import { createContext, useCallback, useEffect, useMemo, useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";
import { useBlocker } from "react-router-dom";
import dayjs from "dayjs";

import {
  type GameLevelRendered,
  type GameDataInterface,
  type GameElement,
  type Asset,
  GameState,
  findElementById,
  type GameOverlayRendered
} from "@shared/game-engine";

import type { GameAssetDto, GameDefinitionDto, GameDataInfoDto } from "@shared/api-client";
import { GameRenderer, GameData, findElementsById } from "@shared/game-engine";
import { createLogger } from "@shared/utils/logging";

import { useAppContext, useBackend, usePersistedState, useTranslation } from "@app/hooks";
import { Button, PageLoading } from "@app/components";
import { mergeGameData } from "@app/utils";

import type { EditMode, EditorTool, UpdateOptions } from "./types";
import { useLayerContentData } from "./useLayerContentData";
import { getAssetUrl } from "@shared/utils/plugins";

const { info: log } = createLogger("EditorContextProvider", { canLog: () => window.__debug__ });

// BIG TODO:
// Refactor this and split into multiple hooks/scopes

const AUTOSAVE_DELAY = 5000;

const DEFAULT_EDITOR_STATE: EditorState = {
  mode: "level",
  interactive: false,
  level: "",
  muted: false,
  language: "es",
  selection: [],
  zoom: 1,
  pan: { x: 0, y: 0 },
  tool: "select",
  leftDrawerWidth: 300,
  rightDrawerWidth: 300
};

export interface EditorState {
  // Main scope
  mode: EditMode;
  interactive: boolean;
  muted: boolean;
  level?: string;
  language: string;
  tool: EditorTool;
  leftDrawerWidth: number;
  rightDrawerWidth: number;

  // Level/Overlay scope
  selection: string[]; // IDs
  zoom: number;
  pan: { x: number; y: number };
}

interface EditorContextProviderType {
  uuid: string;
  version?: string;
  renderer?: GameRenderer;
  isLoading: boolean;
  isMainDataDirty: boolean;
  isGameDataDirty: boolean;
  isLevelTreeDirty: boolean;
  canAutoSave: boolean;
  setVersion: (version: string | undefined) => void;
  setLevel: (level: string | undefined) => void;
  reload: () => void;
  saveAll: () => Promise<void>;

  // Game data scope
  gameDefinition?: GameDefinitionDto;
  gameData?: GameDataInterface;
  gameDataInfo?: GameDataInfoDto;
  updateMainData: (data: Partial<GameDefinitionDto>) => void;
  updateGameData: (data: Partial<GameDataInterface>, options?: UpdateOptions) => void;
  saveMainData: () => Promise<boolean>;
  saveGameData: () => Promise<boolean>;

  // Level/Overlay data scope
  level?: ReturnType<typeof useLayerContentData>;
  overlay?: ReturnType<typeof useLayerContentData>;
  renderedLevelData?: GameLevelRendered;
  renderedOverlayData?: GameOverlayRendered;
  currentLayer?: ReturnType<typeof useLayerContentData>;

  // Editor scope
  editor: EditorState;
  updateEditorState: (updates: Partial<EditorState>) => void;
  setMode: (mode: EditMode) => void;
  setTool: (tool: EditorTool) => void;
  select: (id: string | string[], append?: boolean) => void;
  unselect: (id: string) => void;
  unselectAll: () => void;
  getCurrentLayout: () => GameElement[];
  getSelectedElement: () => GameElement | undefined;
  getSelectedElements: () => GameElement[];

  // Asset scope
  gameAssets?: GameAssetDto[];
  uploadAssets: (files: File[]) => Promise<GameAssetDto[]>;
  deleteAsset: (asset: Asset) => Promise<void>;
  reloadAssets: () => void;
  renderAssetUrl: (url: string, obj?: Record<string, unknown>) => string;
  uploadProgress: number;
  uploadTotal: number;
}

interface EditorContextProviderProps {
  children: React.ReactNode;
  uuid: string;
  version?: string;
  onLoad?: (gameDefinition: GameDefinitionDto, version?: string) => void;
  onUpdateEditorState?: (state: EditorState) => void;
  onError: (error: unknown) => void;
}

export const EditorContext = createContext<EditorContextProviderType>({
  uuid: "",
  renderer: undefined,
  gameDefinition: undefined,
  gameData: undefined,
  gameDataInfo: undefined,
  gameAssets: undefined,
  renderedLevelData: undefined,
  renderedOverlayData: undefined,
  editor: DEFAULT_EDITOR_STATE,
  isLoading: false,
  isMainDataDirty: false,
  isGameDataDirty: false,
  isLevelTreeDirty: false,
  canAutoSave: false,
  setVersion: () => null,
  updateMainData: () => null,
  updateGameData: () => null,
  reload: () => null,
  reloadAssets: () => null,
  saveMainData: () => Promise.resolve(false),
  saveGameData: () => Promise.resolve(false),
  saveAll: () => Promise.resolve(),
  updateEditorState: () => null,
  setMode: () => null,
  setTool: () => null,
  setLevel: () => null,
  select: () => null,
  unselect: () => null,
  unselectAll: () => null,
  getCurrentLayout: () => [],
  getSelectedElement: () => undefined,
  getSelectedElements: () => [],
  uploadAssets: () => Promise.resolve([]),
  deleteAsset: () => Promise.resolve(),
  renderAssetUrl: (src) => src,
  uploadProgress: 0,
  uploadTotal: 0
});

export const EditorContextProvider = (props: EditorContextProviderProps) => {
  const { children, uuid, onLoad, onError, onUpdateEditorState } = props;

  const { gameManager } = useBackend();
  const { confirm, notify } = useAppContext();
  const { t } = useTranslation();

  const [isMainDataDirty, setIsMainDataDirty] = useState(false);
  const [isGameDataDirty, setIsGameDataDirty] = useState(false);
  const [isLevelTreeDirty, setIsLevelTreeDirty] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [uploadTotal, setUploadTotal] = useState<number>(0);

  const [renderer, setRenderer] = useState<GameRenderer | undefined>();
  const [fakeGameState, setFakeGameState] = useState<GameState | undefined>();
  const [gameDefinition, setGameDefinition] = useState<GameDefinitionDto | undefined>();
  const [gameData, setGameData] = useState<GameDataInterface | undefined>();
  const [gameDataInfo, setGameDataInfo] = useState<GameDataInfoDto | undefined>();
  const [gameAssets, setGameAssets] = useState<GameAssetDto[] | undefined>();
  const [renderedLevelData, setRenderedLevelData] = useState<GameLevelRendered | undefined>();
  const [renderedOverlayData, setRenderedOverlayData] = useState<GameOverlayRendered | undefined>();
  const [gameState, setGameState] = useState<GameState | undefined>();

  const [version, setVersion] = usePersistedState<string | undefined>(`editor-${uuid}-version`, undefined, "session");
  const [editor, setEditor] = usePersistedState<EditorState>(`editor-${uuid}`, DEFAULT_EDITOR_STATE, "session");

  const [canAutoSave] = useState(true);

  const handleTransientError = useCallback(
    (error: unknown) => {
      notify(String(error), "error");
    },
    [notify]
  );

  const levelDataLayer = useLayerContentData({
    type: "level",
    autosave: canAutoSave,
    uuid,
    version,
    id: editor.level,
    info: gameDataInfo,
    onCreateSuccess: useCallback(
      (id: string) => {
        // auto-select the level
        setEditor((editor) => ({ ...editor, level: id }));
      },
      [setEditor]
    ),
    onRemoveSuccess: useCallback(
      (id: string) => {
        // Remove the level if it's the current one
        if (id === editor.level) {
          setEditor((editor) => ({ ...editor, level: undefined }));
        }
      },
      [editor.level, setEditor]
    ),
    onRenameSuccess: useCallback(
      (id: string, newId: string) => {
        // Update the editor state if the current level was renamed
        if (id === editor.level) {
          setEditor((editor) => ({ ...editor, level: newId }));
        }
      },
      [editor.level, setEditor]
    ),
    onRequestRefreshGameInfo: useCallback(() => setIsLevelTreeDirty(true), []),
    onError: handleTransientError
  });

  const overlayDataLayer = useLayerContentData({
    type: "overlay",
    autosave: canAutoSave,
    uuid,
    version,
    info: gameDataInfo,
    id: levelDataLayer.data?.overlay,
    onCreateSuccess: useCallback(
      (id: string) => {
        // auto-select the overlay if no overlay is selected
        if (!levelDataLayer.data?.overlay) {
          levelDataLayer.update({ overlay: id });
        }
      },
      [levelDataLayer]
    ),
    onRemoveSuccess: useCallback(
      (id: string) => {
        // Overlay deleting is backend-driven
        if (levelDataLayer.data?.overlay === id) {
          levelDataLayer.update({ overlay: undefined });
        }
      },
      [levelDataLayer]
    ),
    onRenameSuccess: useCallback(() => {
      // Overlay renaming is backend-driven
    }, []),
    onRequestRefreshGameInfo: useCallback(() => setIsLevelTreeDirty(true), []),
    onError: handleTransientError
  });

  const blocker = useBlocker(isMainDataDirty || isGameDataDirty || levelDataLayer.isDirty || overlayDataLayer.isDirty);

  /**
   * Load the game definition
   *
   * @param uuid The game uuid
   */
  const load = useCallback(
    async (uuid: string) => {
      setIsLoading(true);
      log("Loading game", uuid);

      try {
        const newGameDefinition = await gameManager.get({ path: "/:uuid", params: { uuid } });
        setGameDefinition(newGameDefinition);
        setGameState(undefined);
        setIsMainDataDirty(false);

        // Set the version to the last version
        if (!version) {
          setVersion(newGameDefinition.lastVersion?.toString() || newGameDefinition.currentVersion?.toString() || "1");
        }
      } catch (error) {
        onError(error);
      } finally {
        setIsLoading(false);
      }
    },
    [gameManager, onError, setVersion, version]
  );

  /**
   * Load the game assets
   *
   * @param version The game version
   */
  const loadGameAssets = useCallback(async () => {
    if (!uuid) {
      return;
    }

    log("Loading game assets", uuid);

    try {
      const gameAssets = await gameManager.getList({
        path: "/:uuid/assets",
        params: { uuid: uuid }
      });

      setGameAssets(gameAssets.items);
    } catch (error) {
      onError(error);
    }
  }, [uuid, gameManager, onError]);

  /**
   * Load the game data
   *
   * @param version The game version
   */
  const loadGameData = useCallback(
    async (version: string) => {
      log("Loading game data", uuid, version);

      setIsLoading(true);

      try {
        const gameData = await gameManager.get({
          path: "/:uuid/rev/:version",
          params: { uuid, version }
        });
        setGameData(gameData);
        setFakeGameState(new GameState(new GameData(gameData), {}));
        setIsGameDataDirty(false);
      } catch (error) {
        onError(error);
      } finally {
        setIsLoading(false);
      }
    },
    [uuid, gameManager, onError]
  );

  /**
   * Load the game info object, which contains a summary resources, levels, overlays, etc...
   */
  const loadGameInfo = useCallback(
    async (version: string) => {
      gameManager
        .get({ path: "/:uuid/rev/:version/info", params: { uuid, version } })
        .then((info) => {
          setGameDataInfo(info);
          setIsLevelTreeDirty(false);

          if (!editor.level) {
            // Select the first level if there's no level selected
            setEditor((editor) => ({ ...editor, level: info.levelTree[0]?.levelId || info.levels[0] || "" }));
          }
        })
        .catch(() => setGameDataInfo(undefined));
    },
    [editor.level, gameManager, setEditor, uuid]
  );

  /**
   * Save the game definition, only main data
   *
   * @returns A promise that resolves to true if the save was successful
   */
  const saveMainData = useCallback(
    async (gameDefinition: GameDefinitionDto, background = false) => {
      log("Saving game definition", uuid);

      try {
        setIsLoading(true);
        const updatedGameDefinition = await gameManager.patch({ path: "/:uuid", params: { uuid } }, gameDefinition);
        setIsMainDataDirty(false);
        log("Game definition saved", updatedGameDefinition);

        if (!background) {
          setGameDefinition(updatedGameDefinition);
        }

        return true;
      } catch (error) {
        log("Error saving game definition", error);
        onError(error);
        return false;
      } finally {
        setIsLoading(false);
      }
    },
    [gameManager, uuid, onError]
  );

  /**
   * Save the game data excluding levels
   *
   * @returns A promise that resolves to true if the save was successful
   */
  const saveGameData = useCallback(
    async (version: string, gameData: Partial<GameDataInterface>, background = false) => {
      log("Saving game data", uuid, version);

      try {
        setIsLoading(true);

        const updatedGameData = await gameManager.patch(
          { path: "/:uuid/rev/:version", params: { uuid, version } },
          // Ignore level data
          { ...gameData, levels: undefined }
        );
        setIsGameDataDirty(false);
        log("Game data saved successfully");

        if (!background) {
          setGameData(updatedGameData);
        }

        return true;
      } catch (error) {
        log("Error saving game data", error);
        onError(error);
        return false;
      } finally {
        setIsLoading(false);
      }
    },
    [gameManager, uuid, onError]
  );

  /**
   * Update the game definition
   *
   * @param updates The updates to apply
   */
  const updateMainData = useCallback((updates: Partial<GameDefinitionDto>) => {
    log("Updating game definition...");

    setGameDefinition((data) => {
      if (!data) {
        log("No game definition to update!");
        return;
      }

      return mergeGameData(data, updates);
    });

    setIsMainDataDirty(true);
  }, []);

  /**
   * Update the game data excluding levels
   *
   * @param updates The updates to apply
   */
  const updateGameData = useCallback(
    async (updates: Partial<GameDataInterface>, options: UpdateOptions = {}) => {
      log("Updating game data...");
      const { replace: fullUpdate = false } = options;
      setGameData((data) => {
        if (!data) {
          log("No game data to update!");
          return;
        }

        if (fullUpdate) {
          return { ...updates, levels: data?.levels || {} } as GameDataInterface;
        }

        return mergeGameData(data, updates);
      });
      setIsGameDataDirty(true);

      // Save instantly
      version && (await saveGameData(version, updates));
    },
    [version, saveGameData]
  );

  /**
   * Force a reload of the game definition
   */
  const reload = useCallback(() => {
    log("Reloading game...", uuid);
    load(uuid);
  }, [load, uuid]);

  /**
   * Force a reload game assets
   */
  const reloadAssets = useCallback(() => {
    loadGameAssets();
  }, [loadGameAssets]);

  /**
   * Check if there's an updated version in the backend.
   * TODO: This method is a bit too complex and should be split into smaller parts
   **/
  const checkNewVersion = useCallback(async () => {
    log("Checking for updates...", uuid, version);

    if (!gameDefinition || !version) {
      return;
    }

    const updates: { gameDefinition?: Date; gameData?: Date; levelData?: Date; overlayData?: Date } = {
      gameDefinition: undefined,
      gameData: undefined,
      levelData: undefined,
      overlayData: undefined
    };

    const [maybeUpdatedGameDefinition, maybeUpdatedGameData, maybeUpdatedLevelData, maybeUpdatedOverlayData] =
      await Promise.allSettled([
        gameManager.get({ path: "/:uuid", params: { uuid } }),
        gameData && version && gameManager.get({ path: "/:uuid/rev/:version", params: { uuid, version } }),
        levelDataLayer.id &&
          version &&
          gameManager.get({
            path: "/:uuid/rev/:version/levels/:levelId",
            params: { uuid, version, levelId: levelDataLayer.id }
          }),
        overlayDataLayer.id &&
          version &&
          gameManager.get({
            path: "/:uuid/rev/:version/overlays/:overlayId",
            params: { uuid, version, overlayId: overlayDataLayer.id }
          })
      ]);

    if (maybeUpdatedGameDefinition.status === "fulfilled" && maybeUpdatedGameDefinition.value) {
      updates.gameDefinition = dayjs(maybeUpdatedGameDefinition.value.updatedAt).isAfter(gameDefinition.updatedAt)
        ? maybeUpdatedGameDefinition.value.updatedAt
        : undefined;
    } else if (maybeUpdatedGameDefinition.status === "rejected") {
      onError(new Error("Error checking for game definition updates: " + maybeUpdatedGameDefinition.reason));
    }

    if (maybeUpdatedGameData.status === "fulfilled" && maybeUpdatedGameData.value) {
      updates.gameData =
        !!gameData && dayjs(maybeUpdatedGameData.value.updatedAt).isAfter(gameData.updatedAt)
          ? maybeUpdatedGameData.value.updatedAt
          : undefined;
    } else if (maybeUpdatedGameData.status === "rejected") {
      onError(new Error("Error checking for game data updates: " + maybeUpdatedGameData.reason));
    }

    if (maybeUpdatedLevelData.status === "fulfilled" && maybeUpdatedLevelData.value) {
      updates.levelData =
        !!maybeUpdatedLevelData.value._updatedAt &&
        !!overlayDataLayer.data?._updatedAt &&
        dayjs(maybeUpdatedLevelData.value._updatedAt).isAfter(overlayDataLayer.data._updatedAt)
          ? new Date(maybeUpdatedLevelData.value._updatedAt)
          : undefined;
    } else if (maybeUpdatedLevelData.status === "rejected") {
      onError(new Error("Error checking for level data updates: " + maybeUpdatedLevelData.reason));
    }

    if (maybeUpdatedOverlayData.status === "fulfilled" && maybeUpdatedOverlayData.value) {
      updates.overlayData =
        !!maybeUpdatedOverlayData.value._updatedAt &&
        !!overlayDataLayer.data?._updatedAt &&
        dayjs(maybeUpdatedOverlayData.value._updatedAt).isAfter(overlayDataLayer.data._updatedAt)
          ? new Date(maybeUpdatedOverlayData.value._updatedAt)
          : undefined;
    } else if (maybeUpdatedOverlayData.status === "rejected") {
      onError(new Error("Error checking for level data updates: " + maybeUpdatedOverlayData.reason));
    }

    const hasUpdates = updates.gameData || updates.gameData || updates.levelData;

    const needToConfirm =
      (updates.gameData && isMainDataDirty) ||
      (updates.gameData && isGameDataDirty) ||
      (updates.levelData && levelDataLayer.isDirty) ||
      (updates.overlayData && overlayDataLayer.isDirty);

    function doUpdate() {
      log("Updating editor state with new updates");

      // Do reload
      updates.gameDefinition && load(uuid);
      updates.gameData && version && loadGameData(version);
      updates.levelData && levelDataLayer.reload();
      updates.overlayData && overlayDataLayer.reload();
    }

    if (hasUpdates) {
      log("Updates found", updates);

      const updated = updates.gameDefinition || updates.gameData || updates.levelData;

      if (needToConfirm) {
        confirm({
          title: t("editor.gameDataConflictTitle"),
          content: t("editor.gameDataConflictMessage", { updated: dayjs(updated).fromNow() }),
          confirmationText: t("editor.gameDataConflictReload"),
          cancellationText: t("editor.gameDataConflictPreserve"),
          allowClose: false
        }).then(doUpdate);
      } else {
        doUpdate();
      }
    } else {
      log("No updates were found", updates);
    }
  }, [
    confirm,
    gameData,
    gameDefinition,
    gameManager,
    isGameDataDirty,
    isMainDataDirty,
    levelDataLayer,
    load,
    loadGameData,
    onError,
    overlayDataLayer,
    t,
    uuid,
    version
  ]);

  /**
   * Update the editor state
   */
  const updateEditorState = useCallback(
    (updates: Partial<EditorState>) => {
      setEditor((editor) => ({ ...editor, ...updates }));
    },
    [setEditor]
  );

  /**
   * Get the current layout
   */
  const getCurrentLayout = useCallback(() => {
    return (editor.mode === "overlay" ? overlayDataLayer.data?.layout : levelDataLayer.data?.layout) || [];
  }, [editor.mode, levelDataLayer.data, overlayDataLayer.data]);

  /**
   * Select an element
   */
  const select = useCallback(
    (id: string | string[], append = false) => {
      setEditor((editor) => ({
        ...editor,
        selection: Array.isArray(id)
          ? append
            ? [...editor.selection, ...id]
            : [...id]
          : append
            ? [...editor.selection, id]
            : [id]
      }));
    },
    [setEditor]
  );

  /**
   * Unselect an element
   */
  const unselect = useCallback(
    (id: string) => {
      setEditor((editor) => ({
        ...editor,
        selection: editor.selection.filter((element) => element !== id)
      }));
    },
    [setEditor]
  );

  /**
   * Remove selection
   */
  const unselectAll = useCallback(() => {
    setEditor((editor) => ({ ...editor, selection: [] }));
  }, [setEditor]);

  /**
   * Get the selected elements
   */
  const getSelectedElements = useCallback(() => {
    return findElementsById(getCurrentLayout(), editor.selection);
  }, [editor.selection, getCurrentLayout]);

  /**
   * Get the selected element
   */
  const getSelectedElement = useCallback(() => {
    if (editor.selection.length !== 1) {
      return undefined;
    }

    return findElementById(getCurrentLayout(), editor.selection[0]);
  }, [editor.selection, getCurrentLayout]);

  /**
   * Save all pending changes
   */
  const saveAll = useCallback(async () => {
    log("Saving all...");

    if (isMainDataDirty) {
      await saveMainData(gameDefinition!);
    }

    if (isGameDataDirty && version && gameData) {
      await saveGameData(version, gameData);
    }

    if (levelDataLayer.isDirty && !levelDataLayer.isSaving) {
      await levelDataLayer.save();
    }

    if (overlayDataLayer.isDirty && !overlayDataLayer.isSaving) {
      await overlayDataLayer.save();
    }
  }, [
    isMainDataDirty,
    isGameDataDirty,
    version,
    gameData,
    levelDataLayer,
    overlayDataLayer,
    saveMainData,
    gameDefinition,
    saveGameData
  ]);

  /**
   * Set the current mode
   */
  const setMode = useCallback(
    (mode: EditMode) => {
      setEditor((editor) => ({ ...editor, mode }));
    },
    [setEditor]
  );

  /**
   * Set the current tool
   */
  const setTool = useCallback(
    (tool: EditorTool) => {
      setEditor((editor) => ({ ...editor, tool }));
    },
    [setEditor]
  );

  /**
   * Persist all data and set the current level
   */
  const setLevel = useCallback(
    async (level: string | undefined) => {
      if (editor.level === level) {
        return;
      }

      log("Switching level...", level);
      setEditor((editor) => ({ ...editor, level }));
      setMode("level");
    },
    [editor.level, setEditor, setMode]
  );

  /**
   * Upload assets
   */
  const uploadAssets = useCallback(
    async (files: File[]) => {
      const uploadedAssets: GameAssetDto[] = [];
      setUploadTotal(files.length);

      for (const file of files) {
        log("Uploading asset...", files);
        const type = file.type.split("/")[0];
        const name = file.name.trim().replace(/[\s]+/, "-");

        try {
          const asset = await gameManager.upload(
            { path: "/:uuid/assets/:type/:name", params: { uuid, type, name } },
            file
          );

          // TODO: Workaround to ensure the asset was uploaded
          // There's some issue with the upload and the files takes a while to be available
          if (asset.url) {
            for (let i = 0; i < 10; i++) {
              // Verify the asset was uploaded
              const result = await fetch(asset.url, { method: "HEAD" });
              if (result.ok) {
                break;
              }

              // Wait a bit and retry
              await new Promise((resolve) => setTimeout(resolve, 500));
            }
          }

          uploadedAssets.push(asset);
        } catch (error) {
          onError(error);
        }

        setUploadProgress((current) => current + 1);
      }

      setUploadProgress(0);
      setUploadTotal(0);

      return uploadedAssets;
    },
    [gameManager, onError, uuid]
  );

  /**
   * Delete an asset
   */
  const deleteAsset = useCallback(
    async (asset: Asset) => {
      if (!asset.name) {
        return;
      }

      log("Deleting asset...", asset);
      try {
        await gameManager.delete({
          path: "/:uuid/assets/:type/:name",
          params: { uuid: uuid, type: asset.type, name: asset.name }
        });
        reloadAssets();
      } catch (error) {
        onError(error);
      }
    },
    [gameManager, onError, reloadAssets, uuid]
  );

  /**
   * Render an asset URL
   */
  const renderAssetUrl = useCallback(
    (src: string, obj: Record<string, unknown> = {}) => {
      return getAssetUrl(src, obj, {
        assetBaseUrl: gameDefinition?.assetBaseUrl || "",
        pluginBaseUrl: `${import.meta.env.VITE_PLUGIN_BASE_URL}/{pluginType}/{pluginId}/`
      });
    },
    [gameDefinition]
  );

  /** EFFECTS */

  // Prevent leaving the page with unsaved changes
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isMainDataDirty || isGameDataDirty || levelDataLayer.isDirty || overlayDataLayer.isDirty) {
        event.preventDefault();
        event.returnValue = t("editor.blocker.message"); // legacy browsers
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isGameDataDirty, isMainDataDirty, levelDataLayer.isDirty, overlayDataLayer.isDirty, t]);

  // Determine if we can autosave (Check disabled for now. We always auto-save)
  // useEffect(() => {
  //   const isPublic = gameDefinition?.status === GameStatus.PUBLISHED;
  //   const isCurrentPublic = version && gameData?.version === Number(version);
  //   setCanAutoSave(!isPublic || !isCurrentPublic);
  // }, [blocker, gameData?.version, gameDefinition?.status, version]);

  // Auto-save if we leave the route
  useEffect(() => {
    if (blocker.state === "blocked" && canAutoSave) {
      log("Auto-saving game data...");

      // Save & proceed
      saveAll().then(() => {
        blocker.proceed();
      });
    }
  }, [blocker.state, blocker, canAutoSave, saveAll]);

  // Load the game definition
  useEffect(() => {
    load(uuid);
    loadGameAssets();
  }, [uuid, load, loadGameAssets]);

  // Load the game data
  useEffect(() => {
    if (version) {
      // This has implicit reloading the game data
      setIsLevelTreeDirty(true);
    } else {
      setGameData(undefined);
    }
  }, [loadGameData, version]);

  // Cleanup on level change
  useEffect(() => {
    unselectAll();
  }, [editor.level, unselectAll]);

  // Refresh game info
  useEffect(() => {
    if (!version) {
      setGameDataInfo(undefined);
      return;
    }

    // if saved but the level tree is still dirty
    if (isLevelTreeDirty && !levelDataLayer.isDirty) {
      loadGameData(version);
      loadGameInfo(version);
    }
  }, [isLevelTreeDirty, loadGameInfo, version, uuid, loadGameData, levelDataLayer.isDirty]);

  // Update the thumbnails in the stage picker
  useEffect(() => {
    const levelId = levelDataLayer.id;

    if (levelId && levelDataLayer.data?._thumbnail) {
      log("Updating level thumbnail...");
      setGameData((data): GameDataInterface | undefined => {
        if (!data || !data.levels) {
          return;
        }

        const output = {
          ...data,
          levels: {
            ...data.levels,
            [levelId]: {
              // Replace the thumbnail
              ...data.levels[levelId],
              _thumbnail: levelDataLayer.data?._thumbnail
            }
          }
        };

        return output;
      });
    }
  }, [levelDataLayer.data?._thumbnail, levelDataLayer.id]);

  // Render level/overlay data
  useEffect(() => {
    const levelId = editor.level;
    const layerData = levelDataLayer.data;
    const overlayId = layerData?.overlay;

    if (gameData && layerData && levelId) {
      log("Rendering level data...", levelId, "version", layerData.historyUpdatedAt);
      setRenderedLevelData(undefined);

      const renderer = new GameRenderer(
        new GameData({
          ...gameData,
          levels: { [levelId]: layerData },
          overlays: overlayId && overlayDataLayer.data ? { [overlayId]: overlayDataLayer.data || {} } : {}
        }),
        gameState,
        undefined,
        {
          assetBaseUrl: gameDefinition?.assetBaseUrl,
          pluginBaseUrl: import.meta.env.VITE_PLUGIN_BASE_URL,
          keepIds: true
        }
      );

      const nonProxiedGameState = {
        ...fakeGameState?.toExpressionVars(),
        __isProxy__: true // Avoid Proxy creation when evaluating expressions
      };

      const renderedLevel = renderer.renderLevel(levelId, editor.language, nonProxiedGameState);
      const renderedOverlay =
        overlayId && overlayDataLayer.data
          ? renderer.renderOverlay(overlayId, editor.language, nonProxiedGameState)
          : undefined;

      setRenderer(renderer);
      setRenderedLevelData(renderedLevel);
      setRenderedOverlayData(renderedOverlay);
    }
  }, [
    editor.language,
    editor.level,
    fakeGameState,
    gameData,
    gameDefinition,
    gameState,
    levelDataLayer.data,
    overlayDataLayer.data
  ]);

  // Auto-save main data
  useEffect(() => {
    if (!canAutoSave) {
      return;
    }

    if (gameDefinition && isMainDataDirty) {
      log("Autosaving main data...");
      saveMainData(gameDefinition, true);
    }
  }, [canAutoSave, gameDefinition, isMainDataDirty, saveMainData]);

  // Populate game definition changes to parents. Current use cases
  // - Toggle side menu.
  // - Set title
  useEffect(() => {
    if (gameDefinition && onLoad) {
      onLoad(gameDefinition, version);
    }
  }, [gameDefinition, onLoad, updateEditorState, version]);

  // Populate editor changes to parents. Current use cases:
  // - Switch the level in the preview button
  useEffect(() => {
    if (onUpdateEditorState) {
      onUpdateEditorState(editor);
    }
  }, [editor, onUpdateEditorState]);

  // Reload on window focus
  useEffect(() => {
    let lastBlur: Date | undefined;

    function handleFocus() {
      // Do not reload if we just blurred, so we don't hit
      // the backend too much
      if (lastBlur && new Date().getTime() - lastBlur.getTime() < AUTOSAVE_DELAY * 2) {
        return;
      }

      checkNewVersion();
    }

    function handleBlur() {
      saveAll();
      lastBlur = new Date();
    }

    window.addEventListener("focus", handleFocus);
    window.addEventListener("blur", handleBlur);
    return () => {
      window.removeEventListener("focus", handleFocus);
      window.removeEventListener("blur", handleBlur);
    };
  }, [checkNewVersion, saveAll]);

  // Todo: Optimize this
  const contextValue: EditorContextProviderType = useMemo(
    () => ({
      uuid,
      version,
      gameDefinition,
      gameData,
      gameDataInfo,
      gameAssets,
      renderedLevelData,
      renderedOverlayData,
      editor,
      updateEditorState,
      select,
      unselect,
      unselectAll,
      setMode,
      setTool,
      setLevel,
      getCurrentLayout,
      getSelectedElement,
      getSelectedElements,
      isLoading,
      setVersion,
      updateMainData,
      updateGameData,
      isMainDataDirty,
      isGameDataDirty,
      isLevelTreeDirty,
      canAutoSave,
      saveMainData: () => (gameDefinition ? saveMainData(gameDefinition) : Promise.resolve(false)),
      saveGameData: () => (version && gameData ? saveGameData(version, gameData) : Promise.resolve(false)),
      saveAll,
      reload,
      reloadAssets,
      uploadAssets,
      deleteAsset,
      renderAssetUrl,
      uploadProgress,
      uploadTotal,
      renderer,
      level: levelDataLayer,
      overlay: overlayDataLayer,
      currentLayer: editor.mode === "overlay" ? overlayDataLayer : levelDataLayer
    }),
    [
      canAutoSave,
      deleteAsset,
      editor,
      gameAssets,
      gameData,
      gameDataInfo,
      gameDefinition,
      getCurrentLayout,
      getSelectedElement,
      getSelectedElements,
      isGameDataDirty,
      isLevelTreeDirty,
      isLoading,
      isMainDataDirty,
      levelDataLayer,
      overlayDataLayer,
      reload,
      reloadAssets,
      renderAssetUrl,
      renderedLevelData,
      renderedOverlayData,
      renderer,
      saveAll,
      saveGameData,
      saveMainData,
      select,
      setLevel,
      setMode,
      setTool,
      setVersion,
      unselect,
      unselectAll,
      updateEditorState,
      updateGameData,
      updateMainData,
      uploadAssets,
      uploadProgress,
      uploadTotal,
      uuid,
      version
    ]
  );

  return (
    <EditorContext.Provider value={contextValue}>
      {gameDefinition ? children : <PageLoading />}
      <Dialog open={!canAutoSave && blocker.state === "blocked"}>
        <DialogTitle>{t("editor.blocker.title")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography mb={4}>{t("editor.blocker.message")}</Typography>
          </DialogContentText>
          <DialogActions>
            <Button onClick={blocker.reset}>{t("editor.blocker.cancel")}</Button>
            <Button onClick={blocker.proceed} variant='contained' color='error'>
              {t("editor.blocker.proceed")}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </EditorContext.Provider>
  );
};
