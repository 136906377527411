import {
  GameElement,
  GameElementCategory,
  GameElementStageProps,
  GameFlow,
  GameLayerContent,
  GameScoring,
  GameScreenSettings,
  GameTheme,
  GameTimer,
  TransitionSettings,
} from "../types";

export const DEFAULT_SCREEN: GameScreenSettings = {
  width: 1920,
  height: 1080,
};

export const DEFAULT_THEME: GameTheme = {
  background: "#ffffff",
  text: "#000",
};

export const DEFAULT_SCORING: GameScoring = {
  requestClue: 0,
  collectItem: 0,
  unlockSuccess: 0,
  unlockFailed: 0,
  endSecondsLeft: 0,
  completeGame: 0,
};

export const DEFAULT_GAMEFLOW: GameFlow = {
  initialLevelId: "inicio",
  finishLevelId: "fin",
  gameOverLevelId: "gameover",
};

export const DEFAULT_TIMER: GameTimer = {
  startLevelId: "inicio",
  duration: 45,
};

export const DEFAULT_ELEMENT_STAGE: GameElementStageProps = {
  x: 0,
  y: 0,
  width: 100,
  height: 100,
  originX: 0.5,
  originY: 0.5,
  rotation: 0,
  scale: 1,
  opacity: 1,
};

export const DEFAULT_TRANSITIONS: TransitionSettings = { in: {}, out: {} };

export const DEFAULT_LEVEL: GameLayerContent = {
  layout: [],
  transitions: {},
  translations: {},
  overlay: "principal",
};

export const DEFAULT_OVERLAY: GameLayerContent = {
  layout: [],
  transitions: {},
  translations: {},
};

export const DEFAULT_ELEMENT: GameElement = {
  id: "",
  enabled: true,
  type: GameElementCategory.STATIC,
  name: "element",
  component: "<text>",
  stage: DEFAULT_ELEMENT_STAGE,
  properties: {},
};
