import { useCallback, useEffect, useState } from "react";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { withJsonFormsControlProps } from "@jsonforms/react";
import {
  ControlProps,
  RankedTester,
  Resolve,
  isEnumControl,
  isOneOfControl,
  rankWith,
} from "@jsonforms/core";

import { FieldWrapper } from "../common/FieldWrapper";
import { isCustomControl } from "../utils";

interface Props extends ControlProps {
  data: string;
  path: string;
  handleChange: (path: string, value?: string) => void;
}

interface Option {
  title: string;
  const: string | undefined;
}

export function SelectFieldRenderer(props: Props) {
  const {
    label,
    description,
    data,
    schema,
    required,
    path,
    handleChange,
    visible,
    uischema,
    enabled,
    config,
  } = props;
  const [currentValue, setCurrentValue] = useState<string>();
  const [options, setOptions] = useState<Option[]>([]);

  const handleInputChange = useCallback(
    (ev: SelectChangeEvent<string>) => {
      // If the value is empty, set it to undefined
      if (ev.target.value === "") {
        setCurrentValue(undefined);
        handleChange(path, undefined);
        return;
      }

      setCurrentValue(ev.target.value);
      handleChange(path, ev.target.value);
    },
    [handleChange, path]
  );

  useEffect(() => {
    setCurrentValue(data);
  }, [data]);

  useEffect(() => {
    // Figure out the relative path in case of children of Array
    // data.2.children.0.enumSource -> data.2.children.0
    // data.2.child -> data.2
    // data.2.child.property -> data.2
    const lastNumericIndex = path
      .split(/\./g)
      .reverse()
      .findIndex((part) => part.match(/\d/));
    const dataRelativePath =
      lastNumericIndex > -1
        ? path.split(/\./g).slice(0, -lastNumericIndex).join(".")
        : "";

    //  Calculate the final path
    const dataSourcePath =
      uischema.options?.enumSource &&
      (dataRelativePath
        ? `${dataRelativePath}.${uischema.options.enumSource}`
        : uischema.options.enumSource);

    // The data source: either the oneOf or enum field of the schema or the enumSource field of the uischema
    const sourceData = dataSourcePath
      ? Resolve.data(config.context.data || {}, dataSourcePath)
      : schema.oneOf || schema.enum;

    if (!sourceData || !Array.isArray(sourceData)) {
      setOptions([]);
      return;
    }

    // Modifier to use the index as the value of the option
    const useIndexAsValue = uischema.options?.enumUseIndexAsValue || false;

    const options: Option[] = sourceData
      .map((option: unknown, index: number) => {
        if (typeof option === "string" || !option) {
          return {
            title: option || 1 + index,
            const: useIndexAsValue ? index : option,
          };
        }

        if (typeof option === "object" && !!option) {
          const sourceTitleField = (uischema.options?.enumTitleField ||
            "title") as keyof typeof option;
          const sourceValueField = (uischema.options?.enumValueField ||
            "const") as keyof typeof option;

          return {
            title:
              option[sourceTitleField] || option[sourceValueField] || 1 + index,
            const:
              option[sourceValueField] || (useIndexAsValue ? index : option),
          };
        }
      })
      .filter((option): option is Option => !!option);

    if (!required) {
      options.unshift({
        title: "Ninguno",
        const: undefined,
      });
    }

    setOptions(options);
  }, [schema, uischema, config.context.data, path, required]);

  if (!visible) {
    return null;
  }

  return (
    <FieldWrapper label={label} description={description} uiSchema={uischema}>
      <Select
        fullWidth
        value={currentValue ?? ""} // undefined cause issues in the MUI Select component
        onChange={handleInputChange}
        disabled={!enabled}
      >
        {options.map((option, index) => (
          <MenuItem key={`${option.const}_${index}`} value={option.const ?? ""}>
            {option.title}
          </MenuItem>
        ))}
        {data && !options.find((item) => item.const === data) && (
          <MenuItem value={data ?? ""}>{data}</MenuItem>
        )}
      </Select>
    </FieldWrapper>
  );
}

const tester: RankedTester = rankWith(11, (uiSchema, schema, context) => {
  return isEnumControl(uiSchema, schema, context) ||
    isOneOfControl(uiSchema, schema, context) ||
    isCustomControl(uiSchema, "Select")
    ? true
    : false;
});

export default {
  tester,
  renderer: withJsonFormsControlProps(SelectFieldRenderer),
};
