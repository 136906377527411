import { useCallback, useState } from "react";
import { type Accept, useDropzone } from "react-dropzone";

import { Box, Button, CircularProgress, Grid, IconButton, Modal, styled, Typography } from "@mui/material";

import { type GameAssetDto } from "@shared/api-client";
import { ModalBox, ModalButtonBar, ModalContentBox, TitleBox } from "@shared/form-builder/common/MultiSectionModal";

import { Image, UploadBox } from "@app/components";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import AddIcon from "@mui/icons-material/AddCircleOutline";
import { useEditor } from "../useEditor";
import { useTranslation } from "@app/hooks";

const StyledModalBox = styled(ModalBox)(() => ({
  width: 800
}));

const AssetList = styled(Grid)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(100px, 1fr))",
  gap: theme.spacing(2)
}));

const AssetItemBox = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "100%",
  height: 100,
  overflow: "hidden",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.grey[300],
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",

  img: {
    width: "100%",
    height: "100%",
    objectFit: "content"
  },

  "&.selected": {
    outline: `2px solid ${theme.palette.primary.main}`
  }
}));

export interface AssetPickerModalProps {
  open?: boolean;
  mimeType?: Accept;
  multiple?: boolean;
  onUpload?: (file: File[]) => void;
  onSubmit?: (assets: GameAssetDto[]) => void;
  onClose?: () => void;
}

interface UploadProps {
  accept?: Accept;
  onDrop?: (files: File[]) => void;
}

interface AssetItemProps {
  asset: GameAssetDto;
  onSelect?: (asset: GameAssetDto) => void;
  onQuickSelect?: (asset: GameAssetDto) => void;
  selected?: boolean;
}

function ComponentUpload(props: UploadProps) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: props.accept,
    onDrop: props.onDrop
  });

  return (
    <UploadBox {...getRootProps()} className={isDragActive ? "dragging" : ""}>
      <AddIcon fontSize='large' />
      Subir imagen
      <input {...getInputProps()} />
    </UploadBox>
  );
}

function AssetItem(props: AssetItemProps) {
  const { asset, onSelect, onQuickSelect, selected } = props;

  const handleOnSelect = useCallback(() => {
    onSelect?.(asset);
  }, [asset, onSelect]);

  const handleQuickSelect = useCallback(() => {
    onQuickSelect?.(asset);
  }, [asset, onQuickSelect]);

  return (
    <AssetItemBox onClick={handleOnSelect} onDoubleClick={handleQuickSelect} className={selected ? "selected" : ""}>
      <Image src={asset.url} />
    </AssetItemBox>
  );
}

export function AssetPickerModal(props: AssetPickerModalProps) {
  const { open = true, multiple, onClose } = props;
  const { t } = useTranslation();
  const { gameAssets } = useEditor();

  const [selection, setSelection] = useState<GameAssetDto[]>([]);

  const handleSelect = useCallback(
    (asset: GameAssetDto) => {
      if (selection.includes(asset)) {
        setSelection((prev) => prev.filter((a) => a !== asset));
        return;
      }

      setSelection((prev) => (multiple ? [...prev, asset] : [asset]));
    },
    [multiple, selection]
  );

  const handleUpload = useCallback(
    (files: File[]) => {
      props.onUpload?.(files);
    },
    [props]
  );

  const handleCancel = useCallback(() => {
    setSelection([]);
    onClose?.();
  }, [onClose]);

  const handleSubmit = useCallback(() => {
    props.onSubmit?.(selection);
    setSelection([]);
    onClose?.();
  }, [onClose, props, selection]);

  const handleSelectAndSubmit = useCallback(
    (asset: GameAssetDto) => {
      props.onSubmit?.([asset]);
      setSelection([]);
      onClose?.();
    },
    [onClose, props]
  );

  return (
    <Modal open={open || false} onClose={onClose}>
      <StyledModalBox className={open ? "MuiModal-open" : ""}>
        <TitleBox>
          <Typography variant='h6'>{t(multiple ? "editor.selectImages" : "editor.selectImage")}</Typography>
          <IconButton onClick={props.onClose}>
            <HighlightOffIcon />
          </IconButton>
        </TitleBox>
        <ModalContentBox>
          <ComponentUpload onDrop={handleUpload} />
          {gameAssets && (
            <AssetList>
              {gameAssets
                .filter(
                  ({ type }) =>
                    !props.mimeType ||
                    Object.values(props.mimeType)
                      .map((m) => m.map((mime) => mime.split("/")[0]))
                      .flat()
                      .includes(type || "")
                )
                .map((asset) => (
                  <AssetItem
                    key={asset.path}
                    asset={asset}
                    onSelect={handleSelect}
                    onQuickSelect={handleSelectAndSubmit}
                    selected={selection.includes(asset)}
                  />
                ))}
            </AssetList>
          )}
          {gameAssets && !gameAssets.length && <Typography variant='body1'>No hay imágenes disponibles</Typography>}
          {!gameAssets && <CircularProgress />}
        </ModalContentBox>
        <ModalButtonBar mt={2}>
          <Button onClick={handleCancel} variant='contained' color='info'>
            {selection?.length ? t("common.cancel") : t("common.close")}
          </Button>
          <Button onClick={handleSubmit} variant='contained' disabled={!selection?.length}>
            {t("common.apply")}
          </Button>
        </ModalButtonBar>
      </StyledModalBox>
    </Modal>
  );
}
