import { useCallback, useEffect, useState } from "react";
import { Box, styled, Typography } from "@mui/material";
import { Link as SimpleLink } from "react-router-dom";

import { BannerModal, Button, FullScreenLoading } from "@app/components";
import { useAppContext, useBackend, useTranslation } from "@app/hooks";
import { useModals, useNavigate, useParams } from "@app/router";
import { type GameDefinitionDto } from "@shared/api-client";

const StyledInput = styled("input")(({ theme }) => ({
  width: "100%",
  padding: "0.5rem",
  margin: "1rem 0",
  border: "1px solid #ccc",
  borderRadius: "4px",
  textAlign: "center",
  fontSize: "1.1rem",
  fontFamily: "TheUFOLabFont",
  fontWeight: "bold",
  backgroundColor: "transparent",
  color: theme.palette.primary.main,
  borderColor: theme.palette.divider,
  cursor: "pointer",
  transition: "box-shadow 0.3s, background 0.3s, color 0.3s",

  "&:focus": {
    outline: "none"
  },

  "&:hover": {
    boxShadow: `0 0 10px ${theme.palette.primary.main}`
  },

  "&:active": {
    background: theme.palette.primary.main,
    color: theme.palette.common.white
  }
}));

const Actions = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  gap: theme.spacing(2),
  padding: theme.spacing(4, 0, 2),
  boxSizing: "border-box"
}));

const Paragraph = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(2, 0),
  fontSize: "1.2rem",
  textAlign: "center"
}));

// TODO. Editor context is not available here.
// Need to check with generouted.
export default function GamePublishModal() {
  const { uuid, version } = useParams("/dashboard/projects/:uuid/v/:version");

  const { t } = useTranslation();
  const { gameManager } = useBackend();
  const { notify, confirm, superPowers } = useAppContext();
  const modals = useModals();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<"publishing" | "unpublishing" | "creating" | undefined>();
  const [game, setGame] = useState<GameDefinitionDto>();
  const [successVersion, setSuccessVersion] = useState<string | undefined>();

  useEffect(() => {
    // Fetch game data
    gameManager.get({ path: "/:uuid", params: { uuid } }).then(
      (game) => {
        setGame(game);
      },
      () => {
        notify(t("common.error"), "error");
        modals.close();
      }
    );
  }, [gameManager, modals, notify, t, uuid]);

  const handleClose = useCallback(() => {
    if (successVersion) {
      if (successVersion === version) {
        // reload
        modals.close();
        navigate(0);
      } else {
        navigate("/dashboard/projects/:uuid/v/:version/editor", {
          params: { uuid, version: successVersion }
        });
      }
    } else {
      modals.close();
    }
  }, [modals, navigate, successVersion, uuid, version]);

  const handleCreateRevision = useCallback(async () => {
    setLoading("creating");
    const revision = await gameManager.post({ path: "/:uuid/rev", params: { uuid } }, { from: version });
    modals.close();
    navigate("/dashboard/projects/:uuid/v/:version/editor", {
      params: { uuid, version: revision.version.toString() }
    });
  }, [gameManager, modals, navigate, uuid, version]);

  const handleCopy = useCallback(
    async (event: React.MouseEvent<HTMLInputElement>) => {
      event.preventDefault();

      // Copy to clipboard
      try {
        await navigator.clipboard.writeText(event.currentTarget.value);
        notify(t("common.urlCopied"), "success");
      } catch (e) {
        // probably unsupported
        event.currentTarget.select();
      }
    },
    [notify, t]
  );

  const handlePublish = useCallback(async () => {
    // Bump up version
    setLoading("publishing");
    await gameManager.post({ path: "/:uuid/rev/:version/publish", params: { uuid, version } }, {});

    // Set public
    const newGame = await gameManager.patch({ path: "/:uuid", params: { uuid } }, { status: "published" });
    setSuccessVersion(newGame.version.toString());
    setLoading(undefined);

    notify(t("publish.publishSuccess"), "success");
  }, [gameManager, notify, t, uuid, version]);

  const handleUnpublish = useCallback(() => {
    confirm({ title: t("publish.unpublish"), content: t("publish.unpublishConfirm", { name: game?.name }) }).then(
      async () => {
        setLoading("unpublishing");
        await gameManager.patch({ path: "/:uuid", params: { uuid } }, { status: "draft" });
        notify(t("publish.unpublishSuccess"), "success");
        modals.close();
        navigate(0);
      }
    );
  }, [confirm, game?.name, gameManager, modals, navigate, notify, t, uuid]);

  if (!game) {
    return <FullScreenLoading />;
  }

  const isPublic = game.status === "published";
  const isCurrent = game.version === Number(version);
  const isArchived = Number(version) < game.version;
  const publicUrl = import.meta.env.VITE_PLAYER_URL.replace(":uuid", uuid);

  const canBePublished = (!isCurrent || !isPublic) && !isArchived;

  return (
    <BannerModal
      open
      onClose={handleClose}
      title={t("publish.modalTitle", { name: game?.name, version: version })}
      width='680px'
    >
      {!successVersion && !canBePublished && (
        <>
          <Paragraph>
            <strong>{t("publish.alreadyPublished", { name: game.name })}</strong>
          </Paragraph>
          <Paragraph>{t("publish.shareMessage")}</Paragraph>
          <StyledInput type='text' value={publicUrl} readOnly onClick={handleCopy} />

          <Actions>
            <Button onClick={handleClose} disabled={!!loading}>
              {t("common.close")}
            </Button>
            {isPublic && (
              <SimpleLink to={publicUrl} target='_blank'>
                <Button disabled={!!loading}>{t("common.visit")}</Button>
              </SimpleLink>
            )}
            {superPowers && (
              <Button
                onClick={handleCreateRevision}
                variant='contained'
                loading={loading === "creating"}
                disabled={!!loading}
              >
                {t("publish.createRevision")}
              </Button>
            )}
            {isPublic && (
              <Button
                onClick={handleUnpublish}
                variant='contained'
                color='error'
                loading={loading === "unpublishing"}
                disabled={!!loading && loading !== "unpublishing"}
              >
                {t("publish.unpublish")}
              </Button>
            )}
          </Actions>
        </>
      )}

      {!successVersion && canBePublished && (
        <>
          {isPublic && (
            <>
              <Paragraph>{t("publish.currentlyPublished", { name: game.name })}</Paragraph>
              <StyledInput type='text' value={publicUrl} readOnly onClick={handleCopy} />
            </>
          )}
          <Paragraph>
            <strong>{t(!isPublic ? "publish.publishFirstText" : "publish.publishText", { name: game.name })}</strong>
          </Paragraph>

          {!isPublic && <Paragraph>{t("publish.publishWarning")}</Paragraph>}

          <Actions>
            <Button onClick={handleClose} disabled={!!loading}>
              {t("common.cancel")}
            </Button>
            <Button
              onClick={handlePublish}
              variant='contained'
              loading={loading === "publishing"}
              disabled={!!loading && loading !== "publishing"}
            >
              {t(!isPublic ? "publish.doPublish" : "publish.updatePublish")}
            </Button>
            {isPublic && (
              <Button
                onClick={handleUnpublish}
                variant='contained'
                color='error'
                loading={loading === "unpublishing"}
                disabled={!!loading && loading !== "unpublishing"}
              >
                {t("publish.unpublish")}
              </Button>
            )}
          </Actions>
        </>
      )}

      {successVersion && (
        <>
          <Paragraph>
            <strong>{t("publish.successMessage", { name: game.name })}</strong>
          </Paragraph>
          <Paragraph>{t("publish.shareMessage")}</Paragraph>
          <StyledInput type='text' value={publicUrl} readOnly onClick={handleCopy} />

          <Actions>
            <Button onClick={handleClose} disabled={!!loading}>
              {t("common.accept")}
            </Button>
            <Button disabled={!!loading} href={publicUrl}>
              {t("common.visit")}
            </Button>
            {superPowers && (
              <Button
                onClick={handleCreateRevision}
                variant='contained'
                loading={loading === "creating"}
                disabled={!!loading && loading !== "creating"}
              >
                {t("publish.createRevision")}
              </Button>
            )}
          </Actions>
        </>
      )}
    </BannerModal>
  );
}
