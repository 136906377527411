import { ToolbarButton, useEditor } from "@app/editor";
import { useAppContext, useTranslation } from "@app/hooks";
import { getTransitionsSchema, getTransitionsUISchema } from "@app/schemas/transitions.schema";
import { getActionsSchema, getActionsUISchema, getLevelSupportedActions } from "@app/schemas/actions.schema";

import AnimationIcon from "@mui/icons-material/Animation";
import ActionsIcon from "@mui/icons-material/AdsClick";

import { EditorButtonGroup } from "./EditorButtonGroup";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ModalSection, MultiSectionModal } from "@shared/form-builder/common/MultiSectionModal";
import { Button } from "@mui/material";
import {
  DEFAULT_TRANSITIONS,
  type GameActionsMap,
  type GameLayerContent,
  type TransitionSettings
} from "@shared/game-engine";
import { Form } from "@shared/form-builder";

interface Props {
  advanced?: boolean;
  forceOpen?: string;
  onCloseProperties?: () => void;
}

export function LevelToolbarButtons(props: Props) {
  const { advanced, forceOpen, onCloseProperties } = props;

  // To be used within the form context
  const editorContext = useEditor();
  const { confirm } = useAppContext();
  const { t, ts } = useTranslation();
  const { level } = editorContext;

  const [propertiesOpen, setPropertiesOpen] = useState(false);
  const [propertiesSection, setPropertiesSection] = useState<string>();

  const [isDirty, setIsDirty] = useState(false);
  const [currentTransitions, setCurrentTransitions] = useState<TransitionSettings>(DEFAULT_TRANSITIONS);
  const [currentActions, setCurrentActions] = useState<GameActionsMap>({});

  const openProperties = useCallback((section: string) => {
    setPropertiesSection(section);
    setPropertiesOpen(true);
  }, []);

  const handleCloseProperties = useCallback(async () => {
    if (isDirty) {
      await confirm({
        title: t("common.unsavedChanges"),
        content: t("common.unsavedChangesContent")
      });
    }

    setPropertiesOpen(false);
    onCloseProperties?.();
  }, [confirm, isDirty, onCloseProperties, t]);

  const handleTransitionsUpdate = useCallback((data: TransitionSettings) => {
    setCurrentTransitions(data);
    setIsDirty(true);
  }, []);

  const handleActionsUpdate = useCallback((data: GameActionsMap) => {
    setCurrentActions(data);
    setIsDirty(true);
  }, []);

  // Merge all together and submit
  const handleSubmit = useCallback(async () => {
    const updates: Partial<GameLayerContent> = {};

    if (currentTransitions) {
      Object.assign(updates, { transitions: currentTransitions });
    }

    if (currentActions) {
      Object.assign(updates, { actions: currentActions });
    }

    level?.update(updates);
    setIsDirty(false);

    setPropertiesOpen(false);
    onCloseProperties?.();

    return true;
  }, [currentActions, currentTransitions, level, onCloseProperties]);

  // Load the current level data into the form
  useEffect(() => {
    setCurrentActions(level?.data?.actions || {});
    setCurrentTransitions(level?.data?.transitions || DEFAULT_TRANSITIONS);
    setIsDirty(false);
  }, [level, propertiesOpen]);

  useEffect(() => {
    if (forceOpen) {
      setPropertiesOpen(true);
      setPropertiesSection(forceOpen);
    }
  }, [forceOpen]);

  const levelSupportedActions = useMemo(getLevelSupportedActions, []);

  const formContext = useMemo(
    () => ({
      t,
      editorContext
    }),
    [editorContext, t]
  );

  return (
    <>
      <EditorButtonGroup>
        <ToolbarButton
          icon={<ActionsIcon color='error' />}
          title={ts("editor.actions")}
          onClick={() => openProperties("actions")}
        />
        <ToolbarButton
          icon={<AnimationIcon color='success' />}
          title={ts("editor.elementEffects")}
          onClick={() => openProperties("transitions")}
        />
      </EditorButtonGroup>

      <MultiSectionModal
        open={propertiesOpen}
        onClose={handleCloseProperties}
        openSection={propertiesSection}
        footer={
          <>
            <Button onClick={handleCloseProperties} variant='contained' color='info'>
              {isDirty ? t("common.cancel") : t("common.close")}
            </Button>
            <Button onClick={handleSubmit} variant='contained' disabled={!isDirty}>
              {t("common.apply")}
            </Button>
          </>
        }
        alwaysRender
      >
        <ModalSection id='actions' title={ts("editor.levelActionsModalTitle")} icon={<ActionsIcon />} withSideTabs>
          <Form
            data={currentActions}
            schema={getActionsSchema(levelSupportedActions)}
            uiSchema={getActionsUISchema(levelSupportedActions, ["go-to-level"], advanced)}
            onChange={handleActionsUpdate}
            contextData={formContext}
          />
        </ModalSection>
        <ModalSection
          id='transitions'
          title={ts("editor.levelTransitionsModalTitle")}
          icon={<AnimationIcon />}
          withSideTabs
        >
          <Form
            data={currentTransitions}
            schema={getTransitionsSchema()}
            uiSchema={getTransitionsUISchema()}
            onChange={handleTransitionsUpdate}
            contextData={formContext}
          />
        </ModalSection>
      </MultiSectionModal>
    </>
  );
}
