import { useMemo, useState } from "react";
import isEmpty from "lodash/isEmpty";
import {
  LayoutProps,
  RankedTester,
  UISchemaElement,
  rankWith,
} from "@jsonforms/core";
import { renderLayoutElements } from "@jsonforms/material-renderers";
import { withJsonFormsLayoutProps } from "@jsonforms/react";
import { Box, Button } from "@mui/material";

import SettingsIcon from "@mui/icons-material/Settings";

import {
  MultiSectionModal,
  ModalButtonBar,
  ModalSection,
} from "../common/MultiSectionModal";
import { FieldWrapper } from "../common/FieldWrapper";
import { ModalTabs, TabPanel } from "../common/ModalTabs";

import { isLayoutType } from "../utils";
import { ModalLayout } from "../types";
import { ToolbarButton } from "../common/ToolbarButton";
import { Icons } from "../common/Icons";

interface Options {
  toolbarButton?: boolean;
  forceOpen?: boolean;
  noTabs?: boolean;
  onClose?: () => void;
}

export const ModalLayoutRenderer = ({
  uischema,
  renderers,
  cells,
  schema,
  path,
  enabled,
  visible,
}: LayoutProps) => {
  const layout = uischema as ModalLayout;
  const [modalOpen, setModalOpen] = useState(false);
  const { toolbarButton, forceOpen, noTabs, onClose } = (layout.options ||
    {}) as Options;

  const buttonBar = useMemo(
    () => (
      <ModalButtonBar mt={2}>
        <Box flexGrow={1} />
        <Button
          onClick={() => {
            setModalOpen(false);
            onClose?.();
          }}
          variant="contained"
        >
          Cerrar
        </Button>
      </ModalButtonBar>
    ),
    [onClose]
  );

  const tabs = useMemo(
    () =>
      layout.tabs
        ? layout.tabs.map((tab) => (
            <TabPanel
              key={tab.title}
              id={tab.title}
              title={tab.title}
              icon={tab.icon}
            >
              {renderLayoutElements(
                tab.elements as UISchemaElement[],
                schema,
                path,
                enabled,
                renderers,
                cells
              )}
              {buttonBar}
            </TabPanel>
          ))
        : [
            <TabPanel key="main" id="main" title={layout.title}>
              {renderLayoutElements(
                layout.elements as UISchemaElement[],
                schema,
                path,
                enabled,
                renderers,
                cells
              )}
              {buttonBar}
            </TabPanel>,
          ],
    [
      buttonBar,
      cells,
      enabled,
      layout.elements,
      layout.tabs,
      layout.title,
      path,
      renderers,
      schema,
    ]
  );

  const modal = useMemo(
    () => (
      <MultiSectionModal
        open={modalOpen || forceOpen}
        onClose={() => {
          setModalOpen(false);
          onClose?.();
        }}
      >
        <ModalSection
          id="main"
          title={
            layout.title ? `Configuración de ${layout.title}` : "Configuración"
          }
          withSideTabs={!noTabs}
        >
          {noTabs ? (
            tabs[0]
          ) : (
            <ModalTabs activeTab={layout.tabs?.[0].title || "main"}>
              {tabs}
            </ModalTabs>
          )}
        </ModalSection>
      </MultiSectionModal>
    ),
    [forceOpen, layout.tabs, layout.title, modalOpen, noTabs, onClose, tabs]
  );

  if ((isEmpty(layout.elements) && isEmpty(layout.tabs)) || !visible) {
    return null;
  }

  if (toolbarButton) {
    return (
      <>
        <ToolbarButton
          onClick={() => {
            setModalOpen(true);
            onClose?.();
          }}
          icon={
            layout.options?.icon ? Icons[layout.options.icon] : <SettingsIcon />
          }
          title={layout.title}
        />
        {modal}
      </>
    );
  }

  return (
    <FieldWrapper label={layout.title || "Componente"} uiSchema={uischema}>
      <Button
        onClick={() => setModalOpen(true)}
        size="large"
        fullWidth
        variant="contained"
        startIcon={
          layout.options?.icon ? Icons[layout.options?.icon] : <SettingsIcon />
        }
      >
        Configurar
      </Button>
      {modal}
    </FieldWrapper>
  );
};

const tester: RankedTester = rankWith(20, (uiSchema) => {
  return isLayoutType(uiSchema, "ModalLayout");
});

export default {
  tester,
  renderer: withJsonFormsLayoutProps(ModalLayoutRenderer),
};
