import { alpha, Box, styled } from "@mui/material";

import BrokenImageIcon from "@mui/icons-material/BrokenImage";
import CloseCirleImg from "../assets/close-circle.svg";
import {
  ForwardedRef,
  forwardRef,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { DesirableConfig } from "../config";
import { UISchemaElement } from "@jsonforms/core";
import { useDrag, useDrop } from "react-dnd";

// Expected structure of the data
export interface Item {
  id: string;
  asset: string;
  name?: string;
}

export interface DragItem {
  item: Item;
  index: number;
}

export const ImageList = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

export const DroppableWrapper = styled(Box)(({ theme }) => ({
  transition: "padding 0.2s",

  "&.hover-left": {
    paddingLeft: theme.spacing(4),
  },

  "&.dragging": {
    opacity: 0.3,
  },
}));

export const StyledImageItem = styled(Box)(({ theme }) => ({
  width: "40px",
  height: "40px",
  background: "#ffffff",
  borderRadius: "3px",
  borderColor: theme.palette.divider,
  cursor: "default",
  userSelect: "none",
  userDrag: "none",
  webkitUserDrag: "none",
  mozUserDrag: "none",
  overflow: "hidden",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxSizing: "content-box",

  "> img": {
    height: "100%",
    width: "100%",
    objectFit: "cover",
  },

  "&[draggable]": {
    cursor: "grab",
    userDrag: "element",
    webkitUserDrag: "element",
    mozUserDrag: "element",
  },

  "&.error": {
    backgroundColor: alpha(theme.palette.error.main, 0.1),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "> svg": {
      opacity: 0.5,
    },
  },

  "&.removable": {
    position: "relative",

    "&:hover:after": {
      content: '""',
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      borderRadius: "4px",
      background: `url(${CloseCirleImg})`,
      backgroundSize: "30px",
      backgroundPosition: "center center",
      backgroundRepeat: "no-repeat",
      backgroundColor: alpha(theme.palette.error.main, 0.5),
      cursor: "pointer",
    },
  },
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  width: "40px",
  height: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "#ffffff",
  borderRadius: "5px",
  borderColor: theme.palette.divider,
  borderStyle: "dashed",
  borderWidth: "1px",
  boxSizing: "border-box",
  overflow: "hidden",

  "&.highlight": {
    borderColor: theme.palette.primary.main,
  },

  "&.overable:hover": {
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
    cursor: "pointer",
  },

  "&.delete": {
    backgroundColor: alpha(theme.palette.error.main, 0.3),
  },

  "&.hover": {
    borderStyle: "solid",
  },

  "& svg": {
    opacity: 0.3,
  },
}));

export const ImageItem = forwardRef(function (
  props: {
    item?: Item;
    icon?: ReactNode;
    config: DesirableConfig;
    onRemove?: () => void;
    hoverLeft?: boolean;
    dragging?: boolean;
  },
  forwardedRef: ForwardedRef<HTMLDivElement>
) {
  const { item, icon, config, onRemove, hoverLeft, dragging } = props;
  const [error, setError] = useState(false);

  // Translate asset path to URL
  const pluginType = config.context?.manifest?.type;
  const pluginId = config.context?.manifest?.id;
  const src = item
    ? config.context?.editorContext?.renderAssetUrl?.(item.asset, {
        pluginId,
        pluginType,
      }) || item.asset
    : undefined;

  const wrapperClasses = [
    hoverLeft ? "hover-left" : "",
    dragging ? "dragging" : "",
  ].filter(Boolean);

  const classes = [onRemove ? "removable" : "", error ? "error" : ""].filter(
    Boolean
  );

  return (
    <DroppableWrapper ref={forwardedRef} className={wrapperClasses.join()}>
      <StyledImageItem
        ref={forwardedRef}
        onClick={onRemove}
        onError={() => setError(true)}
        className={classes.join(" ")}
      >
        {item && !error && <img src={src} alt={item.name} />}
        {error && <BrokenImageIcon color="error" />}
        {icon && !item && icon}
      </StyledImageItem>
    </DroppableWrapper>
  );
});

export interface DraggableImageItemProps {
  item: Item;
  index: number;
  uischema: UISchemaElement;
  config: DesirableConfig;
  hoverLeft?: boolean;
  onDragStart?: () => void;
  onDragEnd?: () => void;
  onDragMove?: (dragIndex: number, hoverIndex: number) => void;
}

export function DraggableImageItem({
  item,
  index,
  uischema,
  config,
  hoverLeft,
  onDragStart,
  onDragEnd,
  onDragMove,
}: DraggableImageItemProps) {
  const draggableId = uischema.options?.draggableId || "draggable-asset";
  const ref = useRef<HTMLDivElement>(null);
  const [move, setMove] = useState<string>();

  // Drag component
  const [{ isDragging }, drag] = useDrag<
    DragItem,
    void,
    { isDragging: boolean }
  >(() => ({
    type: draggableId,
    item: () => {
      onDragStart?.();
      return { item, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: () => {
      setMove(undefined);
    },
  }));

  const [, drop] = useDrop<DragItem>(() => ({
    accept: draggableId,

    hover: (item, monitor) => {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;

      // Scroll into view
      ref.current.scrollIntoView({ behavior: "smooth", block: "center" });

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
      const clientOffset = monitor.getClientOffset();

      if (!clientOffset) {
        return;
      }

      const hoverClientX = clientOffset.x - hoverBoundingRect.left;
      const isLeft = hoverClientX < hoverMiddleX;

      // Time to actually perform the action
      // Using string to avoid extra useEffect calls
      setMove([dragIndex, index + (isLeft ? 0 : 1)].join("|"));
    },
  }));

  drag(drop(ref));

  // Actual move
  useEffect(() => {
    if (move) {
      const [dragIndex, hoverIndex] = move.split("|").map(Number);
      onDragMove?.(dragIndex, hoverIndex);
    } else {
      onDragEnd?.();
    }
  }, [move, onDragEnd, onDragMove]);

  return (
    <ImageItem
      item={item}
      config={config}
      ref={ref}
      hoverLeft={hoverLeft}
      dragging={isDragging}
    />
  );
}

export function DroppableItem({
  uiSchema,
  onDrop,
  children,
}: {
  uiSchema: UISchemaElement;
  onDrop: (item: Item) => void;
  children?: ReactNode;
}) {
  const [droppedItem, setDroppedItem] = useState<Item | undefined>();

  // Drag component
  const [{ isDragging }, drop] = useDrop<
    DragItem,
    unknown,
    { isDragging: boolean }
  >(() => ({
    accept: uiSchema.options?.draggableId,
    drop: (item, monitor) => {
      if (monitor.isOver({ shallow: true })) {
        setDroppedItem(item.item);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isOver(),
    }),
  }));

  useEffect(() => {
    if (droppedItem) {
      setDroppedItem(undefined);
      onDrop(droppedItem);
    }
  }, [droppedItem, onDrop]);

  return (
    <StyledBox ref={drop} className={isDragging ? "highlight" : ""}>
      {children}
    </StyledBox>
  );
}

export function DroppableBox({
  uischema,
  children,
  onDrop,
  className,
  hoverLeft,
}: {
  uischema: UISchemaElement;
  children?: ReactNode;
  onDrop?: (item: Item) => void;
  className?: string;
  hoverLeft?: boolean;
}) {
  const [droppedAsset, setDroppedAsset] = useState<Item | undefined>();
  const [hover, setHover] = useState(false);

  // Drop component (trash)
  const [, drop] = useDrop<DragItem>(() => ({
    accept: uischema.options?.draggableId,
    drop: (item) => {
      setDroppedAsset(item.item);
    },
    hover: () => {
      setHover(true);
    },
  }));

  useEffect(() => {
    if (droppedAsset) {
      onDrop?.(droppedAsset);
      setDroppedAsset(undefined);
    }
  }, [droppedAsset, onDrop]);

  return (
    <DroppableWrapper className={hoverLeft ? "hover-left" : ""}>
      <StyledBox
        ref={drop}
        className={`${className || ""} ${hover ? "hover" : ""}`}
      >
        {children}
      </StyledBox>
    </DroppableWrapper>
  );
}
